// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".brandIndex_6uDRR{display:grid;grid-auto-flow:column;grid-column-gap:2.46rem;align-items:flex-start;height:4.5rem;justify-content:flex-start;margin-top:2.4rem;overflow:auto;padding:.9rem 0 0}.brandIndex_6uDRR::-webkit-scrollbar{display:none;width:0}.letter_Hw6Z-{display:block;font-size:1.6rem;font-weight:600;line-height:2.2rem;text-transform:uppercase}.letter__disabled_OiLaE{color:var(--gray-2)}.letter_Hw6Z-:not(.letter__disabled_OiLaE):hover,.letter__active_zUVAH{color:var(--primary-main)}.brandsPage_JV2Xp .letter_Hw6Z-{font-size:1.8rem;line-height:2.6rem}@media only screen and (min-width:768px){.brandsPage_JV2Xp{grid-auto-flow:row;grid-template-columns:repeat(3,1fr);height:auto;grid-gap:2.2rem 3.8rem;margin-top:3.2rem;padding:0}.brandsPage_JV2Xp .letter_Hw6Z-{border-bottom:4px solid transparent;color:var(--primary-black)}.brandsPage_JV2Xp .letter__disabled_OiLaE{color:var(--gray-2)}.brandsPage_JV2Xp .letter_Hw6Z-:not(.letter__disabled_OiLaE):hover,.brandsPage_JV2Xp .letter__active_zUVAH{border-color:var(--primary-support);color:var(--primary-black)}}@media only screen and (min-width:1264px){.brandsPage_JV2Xp{grid-template-columns:repeat(4,1fr)}}@media only screen and (min-width:1368px){.brandsPage_JV2Xp{grid-template-columns:repeat(5,1fr)}}@media only screen and (min-width:1490px){.brandsPage_JV2Xp{grid-template-columns:repeat(6,1fr)}}@media only screen and (min-width:1680px){.brandsPage_JV2Xp{grid-template-columns:repeat(7,1fr)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brandIndex": "brandIndex_6uDRR",
	"letter": "letter_Hw6Z-",
	"letter__disabled": "letter__disabled_OiLaE",
	"letter__active": "letter__active_zUVAH",
	"brandsPage": "brandsPage_JV2Xp"
};
module.exports = ___CSS_LOADER_EXPORT___;

import Vue from 'vue';
import VLazyImage from 'v-lazy-image/v2';

const CustomVLazyImage = {
  extends: VLazyImage,
  props: {
    intersectionOptions: {
      type: Object,
      default: () => ({
        rootMargin: '-2px',
      }),
    },
  },
  render(e) {
    const s = e('img', {
      attrs: {
        src: this.srcImage,
        srcset: this.srcsetImage,
        width: 0,
        height: 0,
      },
      domProps: this.$attrs,
      class: { 'v-lazy-image': true, 'v-lazy-image-loaded': this.loaded },
      on: { load: this.load, error: this.error },
    });
    return this.usePicture
      ? e(
        'picture',
        { on: { load: this.load } },
        this.intersected ? [this.$slots.default, s] : [s],
      )
      : s;
  },
};

Vue.component('VLazyImage', CustomVLazyImage);

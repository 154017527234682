
import respUtility from '@/mixins/respUtility';
/**
 * The items' list of the header's categories submenu
 * @category [Header, Menus]
 * @component
 * @example <caption>The block usage (see code)</caption>
 * <HeaderMenuCategoriesChildLevel :menu-item="item" />
 */
export default {
  name: 'HeaderMenuCategoriesChildLevel',
  mixins: [respUtility],
  props: {
    /** @prop {Object} [:menu-item="item"] - The categories menu's item - gql TreeMenuItemType! */
    menuItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      itemHeight: 57,
      itemWidth: 345,
      columnGap: 40,
      boxHeight: 0,
      boxWidth: 0,
      scrollHorizontal: false,
      /** It is used to compute the last item in the column and set class for it */
      maxItemsPerColumn: 1,
    };
  },
  computed: {
    list() {
      if (this.menuItem && this.menuItem.subs) {
        return this.menuItem.subs;
      }
      return [];
    },
  },
  mounted() {
    this.setSizes();
    window.addEventListener('resize', this.setSizes);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSizes);
  },
  methods: {
    /** Set fot the current resolution and items count: boxHeight, boxWidth, maxItemsPerColumn */
    setSizes() {
      if (this.$device.isMobileOrTablet || this.isTouchDevice) {
        this.maxItemsPerColumn = this.list.length;
        return;
      }
      // 64 - paddings, 40 - the submenu's header, 88 - the header's first line height
      this.boxHeight = window.innerHeight - 64 - 40 - 88 - 2;
      // width of the '.header-tree-parent > .header-tree-items' on desktop/tablet
      const parentLevelWidth = 427;
      // 64 - paddings, 32 - the submenu's nudge-right
      const boxMaxWidth = window.innerWidth - parentLevelWidth - 64 - 32;
      this.maxItemsPerColumn = Math.floor(this.boxHeight / this.itemHeight);
      const columns = Math.ceil(this.list.length / this.maxItemsPerColumn);
      this.boxWidth = columns * this.itemWidth + (columns - 1) * this.columnGap;
      if (this.boxWidth > boxMaxWidth) {
        this.boxWidth = boxMaxWidth;
        this.scrollHorizontal = true;
      }
    },
  },
};

export default {
  getData(key) {
    if (process.client) {
      const data = sessionStorage.getItem(key);
      return data ? JSON.parse(data) : null;
    }
    return null;
  },
  setData(key, newData) {
    if (process.client) {
      sessionStorage.setItem(key, JSON.stringify(newData));
    }
  },
  updateData(key, newData) {
    if (process.client) {
      const existingData = this.getData(key) || {};
      const updatedData = { ...existingData, ...newData };
      sessionStorage.setItem(key, JSON.stringify(updatedData));
    }
  },
  removeData(key) {
    if (process.client) {
      sessionStorage.removeItem(key);
    }
  },
};

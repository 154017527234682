
import respUtility from '@/mixins/respUtility';

/**
 * The on site premium protection popup
 * @category [Dialogs, Onsite]
 * @component
 * @example <caption>Dialog usage (see code)</caption>
 * <ProtectionPlanPopup
 *   :v-model="showPopup" @close="closePopup"
 * />
 * @events ['close', 'update', 'remove', 'add']
 */
export default {
  name: 'ProtectionPlanPopup',
  mixins: [respUtility],
  props: {
    /** @prop {Array} [:items="items"] - The protection items */
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      additionalInfo: false,
      userHasMadeManipulations: false,
      chosen: {},
    };
  },
  computed: {
    chosenProtections() {
      if (this.userHasMadeManipulations) {
        return { ...this.chosen };
      }

      const mapPprotectionPlan = (item) => {
        if (typeof item?.protectionPlan === 'string') {
          /**
           * TODO: Refactoring is needed.
           * Incorrect implementation for PDP.
           * item?.protectionPlan must be an object with id property.
           */
          return [item.itemId, item?.protectionPlan];
        }

        /**
         * Correct implementation of item?.protectionPlan
         */
        return [item.itemId, item?.protectionPlan?.id];
      };

      const mapping = this.preSelectionIsRequired()
        ? (item) => [item.itemId, item?.plans[1]?.id]
        : mapPprotectionPlan;

      /**
       * resulted object looks like this
       * {
       *    [itemId]: protectionPlanId,
       *    ...
       * }
       */
      return Object.fromEntries(this.items.map(mapping));
    },
  },
  methods: {
    preSelectionIsRequired() {
      /**
       * For pre-selection, no product should have a protection plan.
       */
      return !this.items.find((item) => item?.protectionPlan);
    },
    handleSelection(value) {
      this.setProtection(value);

      if (!this.userHasMadeManipulations) {
        this.userHasMadeManipulations = true;
      }
    },
    setProtection(value) {
      this.chosen = value;
    },
  },
};

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _912adb5e = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _72475007 = () => interopDefault(import('../pages/clearance-deals/index.vue' /* webpackChunkName: "pages/clearance-deals/index" */))
const _985033f8 = () => interopDefault(import('../pages/daily-deals/index.vue' /* webpackChunkName: "pages/daily-deals/index" */))
const _1d6febb2 = () => interopDefault(import('../pages/furniturepage/index.vue' /* webpackChunkName: "pages/furniturepage/index" */))
const _3f219062 = () => interopDefault(import('../pages/guardian-5-year-protection-plan.vue' /* webpackChunkName: "pages/guardian-5-year-protection-plan" */))
const _1f3008e3 = () => interopDefault(import('../pages/guardian-protection-plan.vue' /* webpackChunkName: "pages/guardian-protection-plan" */))
const _711d0773 = () => interopDefault(import('../pages/homepage/index.vue' /* webpackChunkName: "pages/homepage/index" */))
const _998740b4 = () => interopDefault(import('../pages/onepage/index.vue' /* webpackChunkName: "pages/onepage/index" */))
const _457eb8fc = () => interopDefault(import('../pages/onsite-premium-protection-plan.vue' /* webpackChunkName: "pages/onsite-premium-protection-plan" */))
const _6fafa1df = () => interopDefault(import('../pages/onsite-service-contract.vue' /* webpackChunkName: "pages/onsite-service-contract" */))
const _1951b8fa = () => interopDefault(import('../pages/recently-viewed/index.vue' /* webpackChunkName: "pages/recently-viewed/index" */))
const _7f4275bb = () => interopDefault(import('../pages/reviewsPage/index.vue' /* webpackChunkName: "pages/reviewsPage/index" */))
const _d688cf8c = () => interopDefault(import('../pages/test-info/index.vue' /* webpackChunkName: "pages/test-info/index" */))
const _1772a8b0 = () => interopDefault(import('../pages/testimonialsPage/index.vue' /* webpackChunkName: "pages/testimonialsPage/index" */))
const _b54391a0 = () => interopDefault(import('../pages/catalogsearch/result/index.vue' /* webpackChunkName: "pages/catalogsearch/result/index" */))
const _09180b0c = () => interopDefault(import('../pages/checkout/cart/index.vue' /* webpackChunkName: "pages/checkout/cart/index" */))
const _e379ece4 = () => interopDefault(import('../pages/test-info/miscellaneous1/index.vue' /* webpackChunkName: "pages/test-info/miscellaneous1/index" */))
const _f931e9bc = () => interopDefault(import('../pages/checkout/onepage/success.vue' /* webpackChunkName: "pages/checkout/onepage/success" */))
const _96dadd80 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _53a0c8c7 = () => interopDefault(import('../pages/mattresses/mattresses/_type.vue' /* webpackChunkName: "pages/mattresses/mattresses/_type" */))
const _1b42c600 = () => interopDefault(import('../pages/brand/_brandName/index.vue' /* webpackChunkName: "pages/brand/_brandName/index" */))
const _77e5b711 = () => interopDefault(import('../pages/compare-overview/_id/index.vue' /* webpackChunkName: "pages/compare-overview/_id/index" */))
const _63e9ef76 = () => interopDefault(import('../pages/brand/_brandName/collection_type/_collectionName/index.vue' /* webpackChunkName: "pages/brand/_brandName/collection_type/_collectionName/index" */))
const _41001b53 = () => interopDefault(import('../pages/compare-overview/_id/emqc/_emqcId/index.vue' /* webpackChunkName: "pages/compare-overview/_id/emqc/_emqcId/index" */))
const _2d4b910a = () => interopDefault(import('../pages/_/test-info/index.vue' /* webpackChunkName: "pages/_/test-info/index" */))
const _996763e0 = () => interopDefault(import('../pages/_/index.vue' /* webpackChunkName: "pages/_/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/brands",
    component: _912adb5e,
    name: "brands"
  }, {
    path: "/clearance-deals",
    component: _72475007,
    name: "clearance-deals"
  }, {
    path: "/daily-deals",
    component: _985033f8,
    name: "daily-deals"
  }, {
    path: "/all-furniture",
    component: _1d6febb2,
    name: "furniturepage"
  }, {
    path: "/guardian-5-year-protection-plan",
    component: _3f219062,
    name: "guardian-5-year-protection-plan"
  }, {
    path: "/guardian-protection-plan",
    component: _1f3008e3,
    name: "guardian-protection-plan"
  }, {
    path: "/",
    component: _711d0773,
    name: "homepage"
  }, {
    path: "/onepage",
    component: _998740b4,
    name: "onepage"
  }, {
    path: "/onsite-premium-protection-plan",
    component: _457eb8fc,
    name: "onsite-premium-protection-plan"
  }, {
    path: "/onsite-service-contract",
    component: _6fafa1df,
    name: "onsite-service-contract"
  }, {
    path: "/recently-viewed",
    component: _1951b8fa,
    name: "recently-viewed"
  }, {
    path: "/reviews",
    component: _7f4275bb,
    name: "reviewspage"
  }, {
    path: "/test-info",
    component: _d688cf8c,
    name: "test-info"
  }, {
    path: "/testimonials",
    component: _1772a8b0,
    name: "testimonialsPage"
  }, {
    path: "/catalogsearch/result",
    component: _b54391a0,
    name: "search"
  }, {
    path: "/checkout/cart",
    component: _09180b0c,
    name: "cart"
  }, {
    path: "/test-info/miscellaneous1",
    component: _e379ece4,
    name: "test-info-miscellaneous1"
  }, {
    path: "/checkout/onepage/success",
    component: _f931e9bc,
    name: "success"
  }, {
    path: "/",
    component: _96dadd80,
    name: "index"
  }, {
    path: "/mattresses/mattresses/:type?",
    component: _53a0c8c7,
    name: "mattresses"
  }, {
    path: "/brand/:brandName",
    component: _1b42c600,
    name: "brand"
  }, {
    path: "/compare-overview/:id",
    component: _77e5b711,
    name: "compare"
  }, {
    path: "/brand/:brandName?/collection_type/:collectionName",
    component: _63e9ef76,
    name: "collection"
  }, {
    path: "/compare-overview/:id?/emqc/:emqcId",
    component: _41001b53,
    name: "compare-emqc"
  }, {
    path: "/*/test-info",
    component: _2d4b910a,
    name: "all-test-info"
  }, {
    path: "/*",
    component: _996763e0,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

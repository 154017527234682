// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".link_dt4yn.is_boutique_vBtAy{--secondary-main:#000}.link_dt4yn .v-btn__content{align-items:center;color:var(--primary-main);display:flex;font-size:1.4rem;font-weight:600;gap:8px}.link_dt4yn:hover .v-btn__content{color:var(--secondary-main);-webkit-text-decoration:underline;text-decoration:underline}.link_dt4yn.isLeftDir_JlNX1 .icon_tOE\\+y{order:-1;transform:rotate(180deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "link_dt4yn",
	"is_boutique": "is_boutique_vBtAy",
	"isLeftDir": "isLeftDir_JlNX1",
	"icon": "icon_tOE+y"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap_DTUfo{align-self:flex-end;margin-right:1.6rem;order:-1}@media only screen and (min-width:768px){.wrap_DTUfo{align-self:center}}.wrapDesktop_Q02nd{align-self:flex-end;margin:0;order:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap_DTUfo",
	"wrapDesktop": "wrapDesktop_Q02nd"
};
module.exports = ___CSS_LOADER_EXPORT___;

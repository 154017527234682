const initialCurrent = function getInitialCurrent() {
  return {
    selected: {},
    customPrice: null,
  };
};

const state = () => ({
  searchParams: {},
  currentSelected: initialCurrent(),
  currentSort: '',
  extensionFilters: '',
  isTryMattressFinder: false,
  wishList: [],
  popularProducts: [],
  isSortingSticky: false,
});

export default {
  state,
  getters: {
    defaultParams(state, getters, rootState) {
      // switch (getters.testId)
      return {
        sortBy: rootState?.pageData?.globalType === 'COLLECTION' ? 'PRICE_FROM_HIGHT' : 'RELEVANCE',
        perPage: 'PER_PAGE_36',
        page: 1,
        facet: {},
      };
    },
    isSortingSticky: (state) => state.isSortingSticky,
  },
  mutations: {
    setSearchParams(state, params) {
      state.searchParams = params;
    },
    setExtensionFilters(state, value) {
      state.extensionFilters = value;
    },
    setCurrentSelected(state, selected) {
      state.currentSelected = selected;
    },
    setCurrentSort(state, sort) {
      state.currentSort = sort || '';
    },
    resetCurrentSelected(state) {
      state.currentSelected = initialCurrent();
      state.currentSort = '';
    },
    setSelected(state, selected) {
      const params = { ...(selected || {}) };
      const keys = Object.keys(params);
      keys.forEach((key) => {
        if (params[key] && !Object.keys(params[key]).length) {
          delete params[key];
        }
        // if (key === 'collection_type' && !(params.brand && params.brand.length)) {
        //   delete params.collection_type;
        // }
      });
      const result = {
        selected: params,
      };
      if (params.price && params.price.length && state.currentSelected.customPrice) {
        result.customPrice = null;
      }
      state.currentSelected = {
        ...state.currentSelected,
        ...result,
      };
    },
    removeSelectedFilter(state, selected) {
      const obj = { ...state.currentSelected.selected };
      if (Object.prototype.hasOwnProperty.call(obj, selected)) {
        delete obj[selected];
      }
      state.currentSelected = {
        ...state.currentSelected,
        selected: obj,
      };
    },
    setCustom(state, customPrice) {
      const result = {
        customPrice,
      };
      if (customPrice) {
        const params = { ...state.currentSelected.selected };
        if (params.price && params.price.length) {
          delete params.price;
          result.selected = params;
        }
      }
      state.currentSelected = {
        ...state.currentSelected,
        ...result,
      };
    },
    setTryMattressFinder(state, value) {
      state.isTryMattressFinder = value;
    },
    setWishList(state, value) {
      if (typeof value === 'number') {
        if (state.wishList.includes(value)) {
          const indexItem = state.wishList.indexOf(value);
          state.wishList.splice(indexItem, 1);
        } else {
          state.wishList.push(value);
        }
      } else {
        state.wishList = value;
      }
    },
    setPopularProducts(state, value) {
      state.popularProducts = value;
    },
    setSortingSticky(state, value) {
      state.isSortingSticky = value;
    },
  },
};

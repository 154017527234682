// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo_SDQXi{display:block;flex:0 0 auto;height:32px;width:96px}@media only screen and (min-width:768px){.logo_SDQXi{height:40px;margin:0;width:119px}}@media only screen and (min-width:1264px){.logo_SDQXi{height:56px;width:167px}}.logo_SDQXi.isSimple_epdD7{height:38px;margin:0;width:113px}@media only screen and (min-width:1264px){.logo_SDQXi.isSimple_epdD7{height:48px;width:144px}}.logo_SDQXi:focus-visible{outline:var(--accessibility-outline)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "logo_SDQXi",
	"isSimple": "isSimple_epdD7"
};
module.exports = ___CSS_LOADER_EXPORT___;

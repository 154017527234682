// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title_A4bFi{font-size:1.8rem;font-weight:600;line-height:2.6rem;margin:0 0 1.6rem}.headerBrandsWrap_6hlBX{margin-bottom:112px;margin-bottom:var(--hheader,112px);padding:1.6rem 1.6rem 0}@media only screen and (min-width:768px){.headerBrandsWrap_6hlBX{margin-bottom:72px;margin-bottom:var(--hheader,72px)}}.headerBrandsWrap_6hlBX.isPage_PHPZl{margin-bottom:0}.headerBrandsWrap_6hlBX.isPage_PHPZl .title_A4bFi{font-size:2rem;line-height:2.8rem;margin:0 0 1.7rem;padding-top:.8rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title_A4bFi",
	"headerBrandsWrap": "headerBrandsWrap_6hlBX",
	"isPage": "isPage_PHPZl"
};
module.exports = ___CSS_LOADER_EXPORT___;

import { onLoad } from '@/helpers/onLoad';

export default ({ $isSpider, $config }) => {
  const { criteo } = $config;
  if ($isSpider || !criteo?.criteoDefaultEnable) {
    return;
  }
  onLoad(() => {
    const scriptEle = document.createElement('script');
    scriptEle.innerHTML = `
      (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"5318770"};
      o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},
      n=d.createElement(t),n.src=r,n.async=1,
      n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||
      (f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})
      (window,document,"script","//bat.bing.com/bat.js","window.criteo_q");
    `;
    document.body.appendChild(scriptEle);
    const noscriptEle = document.createElement('noscript');
    noscriptEle.innerHTML = `
      <img src="//bat.bing.com/action/0?ti=5318770&Ver=2"
        height="0" width="0" style="display:none; visibility: hidden;"
      >
    `;
    document.body.appendChild(noscriptEle);
  });
};

import { LOGOUT } from '@/api/apolloHeaderFooterMutations';
import sessionManager from '~/plugins/session-manager';

export default {
  methods: {
    async logOut() {
      try {
        const { customer: { logout } } = await this.$graphql.default.request(LOGOUT);
        if (logout?.success) {
          sessionManager.setData('checkout-settings', { current_step: 'address_step' });
          this.$store.commit('setCustomer', null);
          this.$emit('on-close');
        }
        const messageType = logout?.success ? 'successfully' : 'failed';
        this.$store.commit('addMessage', {
          type: logout?.success ? 'success' : 'error',
          content: `You have ${messageType} signed out.`,
        });
        this.$store.commit('listing/setWishList', []);
        this.$store.commit('cart/setCart', null);
      } catch (e) {
        console.error(e);
        this.$store.commit('addMessage', { type: 'error', content: 'You have failed signed out.' });
      }
    },
  },
};

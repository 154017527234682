
export default {
  data() {
    return {
      defaultProps: {
        accordion: true,
        multiple: true,
        tile: true,
        flat: true,
      },
    };
  },
};

import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=3a5ca4fc"
import script from "./Main.vue?vue&type=script&lang=js"
export * from "./Main.vue?vue&type=script&lang=js"
import style0 from "./Main.vue?vue&type=style&index=0&id=3a5ca4fc&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderMainLogo: require('/var/www/html/js/productlisting/components/templates/header/main/Logo.vue').default,HeaderMenuWrapper: require('/var/www/html/js/productlisting/components/templates/header/menu/Wrapper.vue').default,HeaderSearchWrapper: require('/var/www/html/js/productlisting/components/templates/header/search/Wrapper.vue').default,HeaderAdditionalPhone: require('/var/www/html/js/productlisting/components/templates/header/additional/Phone.vue').default,HeaderAccountWrapper: require('/var/www/html/js/productlisting/components/templates/header/account/Wrapper.vue').default,HeaderWishListLink: require('/var/www/html/js/productlisting/components/templates/header/wishList/Link.vue').default,HeaderCartWrapper: require('/var/www/html/js/productlisting/components/templates/header/cart/Wrapper.vue').default,BaseIcon: require('/var/www/html/js/productlisting/components/common/BaseIcon.vue').default})

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap_pOKE9{font-size:1.6rem;margin-top:1.6rem;text-align:center}@media only screen and (min-width:768px){.wrap_pOKE9{line-height:1.375}}.signUp_BZdcL{color:var(--primary-main)}.signUp_BZdcL:hover{-webkit-text-decoration:underline;text-decoration:underline}.signOut_4f\\+Bh:not(:hover){background-color:var(--primary-white)!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap_pOKE9",
	"signUp": "signUp_BZdcL",
	"signOut": "signOut_4f+Bh"
};
module.exports = ___CSS_LOADER_EXPORT___;

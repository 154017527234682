

import respUtility from '~/mixins/respUtility';
import headerMenuOverlayUtility from '~/mixins/headerMenuOverlayUtility';

export default {
  name: 'HeaderMenuCategoriesDesktop',
  mixins: [respUtility, headerMenuOverlayUtility],
  props: {
    categories: {
      type: Object,
    },
    isMenuPermanent: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({
    nodeId: 'header-categories',
    isMounted: false,
    menuSeo: true,
  }),
  mounted() {
    this.isMounted = true;
  },
  methods: {
    handleMenuOpen(value) {
      if (value) {
        this.menuSeo = false;
      }
    },
  },
};


import strUtility from '@/mixins/strUtility';
/**
 * The product protection plan variants
 * @category [Protection Plan]
 * @component
 * @example <caption>Dialog usage (see code)</caption>
 * <ProtectionPlanVariants :plans="plans" :value="54" @change="setProtection" />
 * @events ['change']
 */
export default {
  name: 'ProtectionPlanVariants',
  mixins: [strUtility],
  props: {
    /** @prop {Object} [:plans="plans"] - The cart item */
    plans: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: [String, Number],
    },
    labels: {
      value: Boolean,
      default: true,
    },
    large: {
      value: Boolean,
      default: false,
    },
  },
  computed: {
    plan: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      },
    },
  },
};


/**
 * The header's account menu's content
 * @category [Header, Menus]
 * @component
 * @example <caption>The block usage (see code)</caption>
 * <HeaderAccountContent :menu-items="menuItems" />
 */
export default {
  name: 'HeaderAccountContent',
  props: {
    /** @prop {Array} [:menu-items="menuItems"] - The user menu - gql MenuItemWithIconType */
  },
  data() {
    return {
      accountMenu: [
        {
          title: 'My Account',
          slug: '/customer/account/welcome',
          iconName: 'IconAccountUser-24x24',
        },
        {
          title: 'My Orders',
          slug: 'sales/guest/form',
          iconName: 'IconAccountOrder-24x24',
        },
        {
          title: 'Reviews',
          slug: '/review/customer',
          iconName: 'IconAccountReview-24x24',
        },
        {
          title: 'Wish List',
          slug: '/wishlist',
          iconName: 'IconAccountWishlist-24x24',
        },
      ],
      infoMenu: [
        {
          title: 'Recently Viewed',
          slug: '/recently-viewed',
          iconName: 'IconAccountPublic-24x24',
          internalRoute: true,
        },
        {
          title: 'Help Center',
          slug: '/help-center',
          iconName: 'IconAccountChatText-24x24',
        },
      ],
      orderLink: {
        slugTrue: '/sales/order/history',
        slugFalse: '/sales/guest/form',
      },
    };
  },
  computed: {
    customer() {
      return this.$store.state.customer;
    },
    updateAccountMenu() {
      const slugOrder = this.customer?.me ? this.orderLink.slugTrue : this.orderLink.slugFalse;
      const accountMenu = this.accountMenu.map((item) => {
        if (item.title === 'My Orders' && slugOrder !== item.slug) {
          const order = {
            ...item,
            slug: slugOrder,
          };
          return order;
        }
        return item;
      });
      return accountMenu;
    },
  },
};


import respUtility from '@/mixins/respUtility';
/**
 * The content of the header's brand menu
 * @category [Header, Menus]
 * @component
 * @example <caption>The block usage (see code)</caption>
 * <BrandCatalogLetter :letter="letter" />
 */
export default {
  name: 'HeaderMenuBrandsCatalogLetter',
  mixins: [respUtility],
  props: {
    /** @prop {Object} [:letter="letter"] - The index's letter data - gql BrandsIndexItemType! */
    letter: {
      type: Object,
      required: true,
    },
    /** @prop {Boolean} [is-menu] - Menu version doesn't have brands' logos */
    isMenu: {
      type: Boolean,
      default: false,
    },
    isPage: {
      type: Boolean,
      default: false,
    },
  },
};

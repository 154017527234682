import { render, staticRenderFns } from "./BaseImage.vue?vue&type=template&id=0b374dda&scoped=true"
import script from "./BaseImage.vue?vue&type=script&lang=js"
export * from "./BaseImage.vue?vue&type=script&lang=js"
import style0 from "./BaseImage.vue?vue&type=style&index=0&id=0b374dda&prod&lang=scss&module=true"
import style1 from "./BaseImage.vue?vue&type=style&index=1&id=0b374dda&prod&lang=scss&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0b374dda",
  null
  
)

export default component.exports
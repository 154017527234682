const initialProduct = {
  general: {},
  gallery: {},
  additional: {},
  configItems: {},
  setSelection: [],
  mfCollection: [],
  accessories: [],
  review: {},
  shippingAndReturn: {},
  relatedSearch: [],
  recomMattress: [],
  grouping: {},
};

const include = {
  product: {},
  bundle: {
    pieceCount: 0,
    items: [],
  },
  relatedProducts: [],
  mattress: {},
  protectionPlan: null,
  foundation: {},
  mattressBedSize: {},
};

const loadingState = {
  general: false,
  gallery: false,
  grouping: false,
  relatedItem: false,
  accessories: false,
  shipping: false,
};

const state = () => ({
  product: { ...initialProduct },
  include: { ...include },
  bedSize: null,
  foundationType: 0,
  canDisplayMattressPopup: false,
  isFoundationQtyChanged: false,
  buildYourOwnLayout: false,
  buildYourOwnLayoutClick: false,
  loadingState: { ...loadingState },
  isMobile: false,
  layoutGroupingAvailable: false,
  showLoader: true,
  disableGrouping: false,
  isHoverProductOutOfStock: null,
  bundleMattressGrouping: null,
  oldDelivery: null,
  groupingProducts: [],
});

export default {
  namespaced: true,
  state,
  mutations: {
    setProduct(state, product) {
      state.product = product || { ...initialProduct };
    },
    updateProduct(state, product) {
      state.product = { ...state.product, ...product };
    },
    setDelivery(state, deliveryData) {
      state.product = { ...state.product, additional: { ...state.product.additional, delivery: deliveryData } };
    },
    setRequiredItems(state, requiredItems) {
      state.product = { ...state.product, configItems: { ...state.product.configItems, required: requiredItems } };
    },
    setAdditionalItems(state, additionalItems) {
      state.product = { ...state.product, configItems: { ...state.product.configItems, additional: additionalItems } };
    },
    setRecommendedMattress(state, recomMattress) {
      state.product = { ...state.product, recomMattress };
    },
    setGeneral(state, general) {
      state.product = { ...state.product, general };
    },
    setAdditional(state, additional) {
      state.product = { ...state.product, additional };
    },
    setGallery(state, gallery) {
      state.product = { ...state.product, gallery };
    },
    setBedSize(state, bedSize) {
      state.bedSize = bedSize;
    },
    setBedTypeId(state, bedTypeId) {
      state.bedTypeId = bedTypeId;
    },
    setFoundationType(state, foundationType) {
      state.foundationType = foundationType;
    },
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    // include method
    setRelatedProducts(state, relatedProducts) {
      state.include = { ...state.include, relatedProducts };
    },
    setMattress(state, mattress) {
      state.include = { ...state.include, mattress };
    },
    setProtectionPlan(state, protectionPlan) {
      state.include = { ...state.include, protectionPlan };
    },
    setmattressBedSize(state, mattressBedSize) {
      state.include = { ...state.include, mattressBedSize };
    },
    setBundleMattressGrouping(state, item) {
      state.bundleMattressGrouping = item;
    },
    setMainProduct(state, product) {
      state.include = { ...state.include, ...{ product } };
    },
    setBundleItems(state, bundleItems) {
      state.include = { ...state.include, bundle: { ...state.include.bundle, items: bundleItems } };
    },
    setPieceCount(state, pieceCount) {
      state.include = { ...state.include, bundle: { ...state.include.bundle, pieceCount } };
    },
    setFoundation(state, foundation) {
      state.include = { ...state.include, foundation };
    },
    resetInclude(state) {
      // reset include except main product
      const includeProduct = { ...state.include.product };
      state.include = { ...include };
      state.include.product = includeProduct;
    },
    resetProduct(state) {
      state.product = { ...initialProduct };
    },
    setCanDisplayMattressPopup(state, canDisplayMattressPopup) {
      state.canDisplayMattressPopup = canDisplayMattressPopup;
    },
    setIsFoundationQtyChanged(state, isFoundationQtyChanged) {
      state.isFoundationQtyChanged = isFoundationQtyChanged;
    },
    setBuildYourOwnLayout(state, buildYourOwnLayout) {
      state.buildYourOwnLayout = buildYourOwnLayout;
    },
    setBuildYourOwnLayoutClick(state, buildYourOwnLayoutClick) {
      state.buildYourOwnLayoutClick = buildYourOwnLayoutClick;
    },
    setLayoutGroupingAvailable(state, value) {
      state.layoutGroupingAvailable = value;
    },
    setPplanInclude(state, pplanInclude) {
      state.product = { ...state.product, pplanInclude };
    },
    updateLoadingState(state, stateData) {
      state.loadingState = { ...state.loadingState, ...stateData };
    },
    setMfCollection(state, mfCollection) {
      state.product = { ...state.product, mfCollection };
    },
    setMfCollectionItems(state, items) {
      state.product = { ...state.product, mfCollection: { ...state.product.mfCollection, items } };
    },
    setConfigItems(state, configItems) {
      state.product = { ...state.product, configItems };
    },
    setShowLoader(state, showLoader) {
      state.showLoader = showLoader;
    },
    setDisableGrouping(state, disableGrouping) {
      // eslint-disable-next-line object-shorthand
      state.disableGrouping = disableGrouping;
    },
    setIsHoverProductOutOfStock(state, isOutOfStock) {
      // eslint-disable-next-line object-shorthand
      state.isHoverProductOutOfStock = isOutOfStock;
    },
    setAccessories(state, accessories) {
      state.product = { ...state.product, accessories };
    },
    setGrouping(state, grouping) {
      state.product = { ...state.product, grouping };
    },
    resetGroupingProducts(state) {
      state.groupingProducts = [];
    },
    addGroupingProduct(state, productData) {
      state.groupingProducts = [...state.groupingProducts, productData];
    },
    setGroupingProduct(state, productData) {
      state.groupingProducts = productData;
    },
    updateProtectionPlanList(state, payload = {}) {
      const payloadEntries = Object.entries(payload);

      if (!payloadEntries.length) {
        return;
      }

      const toAdd = [];
      const toRemove = [];

      /**
       * Sorting
       */
      payloadEntries.forEach(([itemId, addAction]) => {
        const targetArray = addAction ? toAdd : toRemove;
        targetArray.push(itemId);
      });

      /**
       * Filtering. Leave what doesn't need to be removed
       * from the list of protection plans.
       */
      const protectionPlanEntries = Object
        .entries(state.include.protectionPlan || [])
        .filter(([itemId]) => !toRemove.includes(itemId));

      /**
       * Add new entries
       */
      toAdd.forEach((itemId) => {
        const isExisting = protectionPlanEntries
          .find(([itemIdOnEntry]) => itemIdOnEntry === itemId);

        /**
         * We skip itemIds that are already in the list of protection plans
         */
        if (isExisting) {
          return;
        }

        protectionPlanEntries.push([itemId, undefined]);
      });

      const protectionPlan = Object.fromEntries(protectionPlanEntries);
      state.include = { ...state.include, protectionPlan };
    },
    setOldDelivery(state, oldDelivery) {
      state.oldDelivery = oldDelivery;
    },
  },
  getters: {
    mainPDPClearance(state) {
      const productGeneral = state.product?.general;
      const price = productGeneral?.price?.price || 0;
      const clearanceQty = productGeneral?.price?.clearanceQty || 0;
      const originalPrice = productGeneral?.price?.originalPrice || 0;
      const finalPrice = price;
      if (clearanceQty > 0 && clearanceQty < 10
        && originalPrice > finalPrice) {
        return {
          qty: clearanceQty,
          originalPrice,
          price: finalPrice,
        };
      }
      return null;
    },
    prepareCartData(state) {
      const includeData = state.include;
      const relatedData = includeData.relatedProducts;
      const mattressData = includeData.mattress;
      const bundleData = includeData.bundle;
      const isBundleProduct = state.product?.general?.typeId === 'BUNDLE';
      const isMattressProduct = state.product?.general?.category === 'Mattresses';
      const foundationData = includeData.foundation;
      const mattressBedSizeData = includeData.mattressBedSize;
      const deliveryZipcode = state.product?.additional?.delivery?.zipcode || '';

      const relatedCartData = relatedData.length > 0
        ? relatedData.map(({ id, qty }) => ({ product: id, qty: Number(qty) })) : null;
      // const freightRelatedProduct = includeData.relatedProducts.length === 1;
      const setSelection = isBundleProduct ? bundleData.pieceCount : null;

      let bundleCartData = null;
      if (isBundleProduct && bundleData.items.length) {
        bundleCartData = bundleData.items
          .map(({ qty, optionId, selectionId }) => ({ qty: Number(qty), option: optionId, selection: selectionId }));
      }
      let foundationInputData = null;
      if (!isBundleProduct && Object.keys(foundationData).length > 0) {
        foundationInputData = {
          id: foundationData.id,
          qty: foundationData.qty,
        };
      }
      if (isBundleProduct && isMattressProduct) {
        bundleCartData = [
          {
            option: Number(mattressBedSizeData.optionId),
            selection: Number(mattressBedSizeData.selectionId),
            qty: includeData.product.qty,
          },
        ];

        if (Object.keys(foundationData).length > 0) {
          bundleCartData.push({
            option: Number(foundationData.optionId),
            selection: Number(foundationData.selectionId),
            qty: foundationData?.qty || 0,
          });
        }
      }

      let mattressCartData = null;
      if (Object.keys(mattressData).length) {
        mattressCartData = {
          mattressId: mattressData.mattressId,
          mattressBedId: mattressData.bedId,
          mattressOption: {
            size: mattressData.mattressSize.toUpperCase(),
            comfortLevel: mattressData.comfortLevel.toUpperCase(),
          },
          mattressBundleOption: {
            qty: Number(mattressData.qty),
            option: mattressData.optionId,
            selection: mattressData.selectionId,
          },
        };
      }
      const protectionData = Object
        .entries(includeData.protectionPlan || {})
        .map(([item, plan]) => ({ item, plan }))
        .filter((item) => item.plan);

      if (isBundleProduct && isMattressProduct) {
        const index = protectionData.findIndex(({ item }) => (
          item.toString() === state.product?.general?.id.toString()
        ));

        if (index > -1) {
          const { plan } = protectionData[index];
          const { selectionId: item } = mattressBedSizeData;

          protectionData.splice(index, 1, { item, plan });
        }
      }

      const cartData = {
        product: Number(includeData.product.id),
        qty: includeData.product.qty,
        freightRelatedProduct: null,
        relatedProduct: relatedCartData,
        setSelection,
        bundleOption: bundleCartData,
        foundation: foundationInputData,
        mattress: mattressCartData,
        protectionPlan: protectionData,
        customOptions: null,
        zipcode: deliveryZipcode,
      };
      return cartData;
    },
    isOutOfStock(state) {
      let isOutOfStock = false;
      if (state.product?.general?.typeId === 'BUNDLE'
        && state.product?.general?.category !== 'Sectionals') {
        if (state.product?.general?.category === 'Mattresses') {
          isOutOfStock = isOutOfStock || (state.bundleMattressGrouping?.product?.additional?.outOfStock || false);
        } else {
          const bundleItems = state.include?.bundle?.items || [];
          if (bundleItems.length) {
            isOutOfStock = isOutOfStock || bundleItems.some((item) => item.qty > 0 && item.outOfStock);
          } else {
            isOutOfStock = isOutOfStock || (state.product?.additional?.outOfStock || false);
          }
        }
      } else {
        isOutOfStock = isOutOfStock || (state.product?.additional?.outOfStock || false);
      }
      isOutOfStock = isOutOfStock || (state.include?.foundation?.outOfStock || false);
      isOutOfStock = isOutOfStock
        || state.include?.relatedProducts?.labels?.includes('out_of_stock')
        || false;
      return isOutOfStock;
    },
    totalPricePDP(state) {
      const isBundleProduct = state.product?.general?.typeId === 'BUNDLE';
      const isMattressProduct = state.product?.general?.category === 'Mattresses';
      const storeBedSize = state.bedSize;

      if (!isBundleProduct || isMattressProduct) {
        let price = state.product?.general?.price?.price;
        if (isMattressProduct && isBundleProduct && storeBedSize) {
          const product = state.product?.grouping?.items
            .find((groupingItem) => groupingItem?.product?.general?.id === storeBedSize);
          price = product?.product?.general?.price?.selectionPrice
            || product?.product?.general?.price?.price;
        }
        return price;
      }
      const { layoutGroupingAvailable } = state;
      const isSectional = state.product?.general?.category === 'Sectional';
      const { buildYourOwnLayout } = state;
      if ((isSectional || layoutGroupingAvailable) && !buildYourOwnLayout) {
        return state.product?.general?.price?.price;
      }
      let bundleTotal = state.product?.general?.price?.price;
      const includeItems = state.include;
      const bundleItems = includeItems?.bundle?.items || [];
      const mattress = includeItems?.mattress || {};

      if (bundleItems.length) {
        bundleTotal = bundleItems.reduce(
          (total, bundleItem) => total + bundleItem.qty * bundleItem.price,
          0,
        );
      }

      if (Object.keys(mattress).length) {
        bundleTotal += mattress.qty * mattress.price;
      }

      return bundleTotal;
    },
  },
  actions: {
    resetCanDisplayMattressPopup({ commit, state }) {
      const canDisplayMattressPopup = state.product.additional?.canDisplayMattressPopup || false;
      commit('setCanDisplayMattressPopup', canDisplayMattressPopup);
    },
    updateMainProduct({ commit, dispatch, state }, productData) {
      commit('setMainProduct', productData);
      const { foundation } = state.include;
      if (Object.keys(foundation).length && !state.isFoundationQtyChanged) {
        dispatch('updateFoundation', { ...foundation, qty: productData.qty });
      }
    },
    updateFoundation({ commit }, foundation) {
      if (!Object.keys(foundation).length) {
        commit('setIsFoundationQtyChanged', false);
      }
      commit('setFoundation', foundation);
    },
    setMainProduct({ dispatch, state, getters }) {
      const product = state.product?.general;
      const productData = {
        id: product?.id,
        name: product?.name,
        slug: product?.slug,
        typeId: product?.typeId,
        img: product?.image?.src,
        price: product?.price?.price,
        qty: 1,
        type: 'product',
        clearance: getters.mainPDPClearance,
      };
      dispatch('updateMainProduct', productData);
      dispatch('resetCanDisplayMattressPopup');
    },
    resetBundleItemsQty({ commit, state }) {
      const { pieceCount } = state.include.bundle;
      let bundleItems = [...state.include.bundle.items];
      bundleItems = bundleItems.map((item) => {
        const requiredItems = state.product.configItems?.required || [];
        const additionalItems = state.product.configItems?.additional || [];
        const allItems = [...requiredItems, ...additionalItems];
        const selectedItem = allItems.filter((i) => (i.optionId === item.optionId));
        let defaultQty = 1;
        if (selectedItem.length && selectedItem[0].selections) {
          defaultQty = selectedItem[0].selections.filter((selection) => (selection.id === item.selectionId))[0].qty;
        }
        return {
          ...item,
          qty: (item.pieceCount === 1 || (item.pieceCount !== 0 && item.pieceCount <= pieceCount)) ? defaultQty : 0,
        };
      });
      commit('setBundleItems', bundleItems);
    },
    updateRelatedProduct({ commit, state }, product) {
      const relatedProducts = [...state.include.relatedProducts];
      const existingIndex = relatedProducts.findIndex((item) => item.id === product.id);
      if (existingIndex !== -1) {
        if (product.qty > 0) {
          relatedProducts[existingIndex] = product;
        } else {
          relatedProducts.splice(existingIndex, 1);
        }
      } else if (product.qty > 0) {
        relatedProducts.push(product);
      }
      commit('setRelatedProducts', relatedProducts);
      commit('updateProtectionPlanList', {
        [product.id]: product.qty > 0,
      });
    },
    updateBundleItem({ commit, state }, bundleItem) {
      const bundleItems = [...state.include.bundle.items];
      const existingIndex = bundleItems.findIndex((item) => item.optionId === bundleItem.optionId);
      if (existingIndex !== -1) {
        bundleItems[existingIndex] = bundleItem;
      } else {
        bundleItems.push(bundleItem);
      }
      commit('setBundleItems', bundleItems);
      commit('updateProtectionPlanList', {
        [bundleItem.selectionId]: bundleItem.qty > 0,
      });
    },
    setBundleItems({ commit, state }) {
      const configItems = state.product.configItems || {};
      const required = configItems.required || [];
      const bedSelection = required
        .map((item) => item.selections.find((selection) => selection?.product?.bedTypeId && selection.default))
        .find((selection) => selection);
      if (bedSelection && bedSelection?.product) {
        commit('setBedSize', bedSelection?.product?.id);
        commit('setBedTypeId', bedSelection?.product.bedTypeId);
      }
      const options = [];
      const optionFormatter = (option, checkForClearance) => {
        let selectedItem = (option?.selections || []).find((item) => item.default) || {};
        if (!Object.keys(selectedItem).length) {
          selectedItem = (option?.selections || []).reduce((lowest, item) => (
            item.selectionPrice < lowest.selectionPrice ? item : lowest
          ));
        }
        let clearance = null;
        const price = selectedItem?.product?.price?.price || 0;
        const mainProductPrice = state.product?.general?.price;
        const clearanceQty = mainProductPrice?.clearanceQty || 0;
        const mpFinalPrice = mainProductPrice?.price || 0;
        const mpOriginalPrice = mainProductPrice?.originalPrice || 0;
        const adjustedPrice = (price * 100) / mpFinalPrice;
        const originalPrice = (adjustedPrice * mpOriginalPrice) / 100 || 0;
        const finalPrice = price;
        if (clearanceQty > 0 && clearanceQty < 10
            && originalPrice > finalPrice && checkForClearance) {
          clearance = {
            qty: clearanceQty,
            originalPrice,
            price: finalPrice,
          };
        }
        return {
          optionId: option?.optionId,
          selectionId: selectedItem?.id,
          isRequired: option?.isRequired,
          pieceCount: option?.pieceCount,
          id: selectedItem?.product?.id,
          name: selectedItem?.title || selectedItem?.product?.name,
          slug: selectedItem?.product?.slug,
          img: selectedItem?.product?.image?.src,
          price: selectedItem?.selectionPrice,
          simplePrice: selectedItem?.product?.price?.price,
          regularPrice: selectedItem?.product?.price?.regularPrice,
          finalPrice: selectedItem?.product?.price?.finalPrice,
          brandId: selectedItem.brandId,
          type: 'bundle',
          clearance,
          visibility: selectedItem?.product?.visibility,
          setOf: selectedItem?.setOf,
          qtyIncrement: selectedItem?.qtyIncrement,
          qty: option?.isRequired ? selectedItem?.qty : 0,
          outOfStock: selectedItem?.product?.labels?.includes('out_of_stock') || false,
          defaultQty: selectedItem?.defaultQty,
          delivery: selectedItem?.product?.delivery,
        };
      };
      options.push(...required.map((option) => optionFormatter(option, true)));
      options.push(...(configItems.additional || []).map((option) => optionFormatter(option, false)));
      commit('setBundleItems', options);
    },
    updateRecomMattress({ commit }, mattress) {
      commit('setMattress', mattress.qty > 0 ? mattress : {});
      commit('updateProtectionPlanList', {
        [mattress.selectionId]: mattress.qty > 0,
      });
    },
    updateMainProductPrice({ dispatch }, { item, price }) {
      dispatch('updateMainProduct', { ...item, price });
    },
    updateDeliveryForBundle({ commit, state }) {
      const bundleItems = state.include.bundle.items;
      const isSectional = state.product.general.category === 'Sectionals';

      if (isSectional) {
        return null;
      }

      function parseEndDate(dateString) {
        const rangeParts = dateString.split(' - ');
        const endDateString = rangeParts[1] ? rangeParts[1].trim() : rangeParts[0].trim();
        return new Date(endDateString);
      }

      // Filter items with qty > 0 and parse their delivery dates
      const validDeliveryItems = bundleItems
        .filter((item) => item.qty > 0 && !item.outOfStock && item.delivery && item.delivery.date)
        .map((item) => ({
          ...item,
          parsedDate: parseEndDate(item.delivery.date),
        }))
        .filter((item) => !Number.isNaN(item.parsedDate)); // Filter out invalid dates

      if (validDeliveryItems.length === 0) {
        return null;
      }

      const contactUsDate = validDeliveryItems.find((item) => item.delivery.dateHtml.toLowerCase().includes('contact us'));
      if (contactUsDate) {
        commit('setDelivery', contactUsDate.delivery);
        return contactUsDate.delivery;
      }

      // Find the latest date among filtered items
      const maxDeliveryDate = new Date(Math.max(...validDeliveryItems.map((item) => item.parsedDate)));

      // Return the delivery arrays of items with the latest delivery date
      const deliveryArray = validDeliveryItems
        .filter((item) => item.parsedDate.getTime() === maxDeliveryDate.getTime())
        .map((item) => item.delivery);
      commit('setDelivery', deliveryArray[0]);
      return deliveryArray[0];
    },
    updateDeliveryForSimple({ commit, state }) {
      const relatedProducts = [...state.include.relatedProducts];

      function parseEndDate(dateString) {
        const rangeParts = dateString.split(' - ');
        const endDateString = rangeParts[1] ? rangeParts[1].trim() : rangeParts[0].trim();
        return new Date(endDateString);
      }

      // Filter related products to ensure they have a valid delivery date
      const validDeliveryItems = relatedProducts
        .filter((item) => item.qty > 0 && item.delivery && item.delivery.date)
        .map((item) => ({
          ...item,
          parsedDate: parseEndDate(item.delivery.date), // Parse date for comparison
        }))
        .filter((item) => !Number.isNaN(item.parsedDate)); // Remove any items with invalid dates

      const isContactUsActive = state.product.additional.delivery.dateHtml.toLowerCase().includes('contact us');
      if (validDeliveryItems.length === 0 || isContactUsActive) {
        if (state.oldDelivery) {
          commit('setDelivery', state.oldDelivery);
        }
        return state.oldDelivery;
      }

      const contactUsDate = validDeliveryItems.find((item) => item.delivery.dateHtml.toLowerCase().includes('contact us'));
      if (contactUsDate) {
        commit('setOldDelivery', state.product.additional.delivery);
        commit('setDelivery', contactUsDate.delivery);
        return contactUsDate.delivery;
      }

      // Find the maximum date from the valid delivery items
      const maxDeliveryDate = new Date(Math.max(...validDeliveryItems.map((item) => item.parsedDate)));

      // Find the item(s) that match the maximum delivery date and return their delivery details
      const deliveryArray = validDeliveryItems
        .filter((item) => item.parsedDate.getTime() === maxDeliveryDate.getTime())
        .map((item) => item.delivery);

      commit('setOldDelivery', state.product.additional.delivery);
      commit('setDelivery', deliveryArray[0]);
      return deliveryArray[0];
    },
  },
};

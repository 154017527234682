import { onLoad } from '@/helpers/onLoad';

export default ({ $isSpider, $config }) => {
  const { qualityBadge } = $config;
  if ($isSpider || !qualityBadge?.siteUrl) {
    return;
  }
  onLoad(() => {
    const scriptEle = document.createElement('script');
    scriptEle.setAttribute('src', qualityBadge.siteUrl);
    scriptEle.setAttribute('defer', 'true');
    scriptEle.addEventListener('load', () => {
      window.merchantwidget.start({
        position: qualityBadge.position,
        sideMargin: qualityBadge.sideMargin,
        bottomMargin: qualityBadge.bottomMargin,
        mobileSideMargin: qualityBadge.mobileSideMargin,
        mobileBottomMargin: qualityBadge.mobileBottomMargin,
      });
    });
    document.head.appendChild(scriptEle);
  });
};

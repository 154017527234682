
import { GET_MAIN_MENU, GET_BRAND } from '~/api/apolloQueries';

import BaseIcon from '../common/BaseIcon.vue';

export default {
  components: { BaseIcon },
  name: 'StaticHeader',
  apollo: {
    mainMenu: {
      query: GET_MAIN_MENU,
      update(data) {
        return data.menu.mainMenu;
      },
      // Error handling
      error(error) {
        console.error('We\'ve got an error!', error);
      },
    },
    brands: {
      query: GET_BRAND,
      update(data) {
        return data.menu.brands;
      },
      // Error handling
      error(error) {
        console.error('We\'ve got an error!', error);
      },
    },
  },
};


export default {
  name: 'HeaderMenuCategoriesSeo',
  props: {
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".copyright_Pu6jT{align-items:center;display:flex;flex-wrap:wrap;gap:8px;justify-content:center;margin:0}@media only screen and (min-width:768px){.copyright_Pu6jT{margin:2px 0 -2px}}@media only screen and (min-width:1264px){.copyright_Pu6jT{margin:2px 0 0}}.copyright_Pu6jT.checkoutCopyright_xxVRJ{flex-direction:column;gap:16px}@media only screen and (min-width:1264px){.copyright_Pu6jT.checkoutCopyright_xxVRJ{flex-direction:row}}.copyright_Pu6jT.checkoutCopyright_xxVRJ .link_idurt{color:var(--primary-black)}.copyright_Pu6jT.checkoutCopyright_xxVRJ .link_idurt:hover{color:var(--primary-black);-webkit-text-decoration:none;text-decoration:none}@media only screen and (min-width:1264px){.copyright_Pu6jT.checkoutCopyright_xxVRJ .link_idurt{color:var(--primary-main)}.copyright_Pu6jT.checkoutCopyright_xxVRJ .link_idurt:hover{color:var(--primary-main);-webkit-text-decoration:underline;text-decoration:underline}}.copyright_Pu6jT.checkoutCopyright_xxVRJ .link_idurt:not(:last-child){margin-right:8px;padding-right:8px}.links_7F4M4{display:flex;line-height:24px}.link_idurt:hover{color:var(--primary-main);-webkit-text-decoration:underline;text-decoration:underline}.link_idurt:not(:last-child){border-right:1px solid var(--gray-4);margin-right:16px;padding-right:16px}@media only screen and (min-width:1264px){.link_idurt:not(:last-child){margin-right:8px;padding-right:8px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyright": "copyright_Pu6jT",
	"checkoutCopyright": "checkoutCopyright_xxVRJ",
	"link": "link_idurt",
	"links": "links_7F4M4"
};
module.exports = ___CSS_LOADER_EXPORT___;


import respUtility from '~/mixins/respUtility';
import headerMenuOverlayUtility from '~/mixins/headerMenuOverlayUtility';
/**
 * The header's brand menu
 * @category [Header, Menus]
 * @component
 * @example <caption>The block usage (see code)</caption>
 * <HeaderMenuBrandsDesktop :brands="brands" />
 */
export default {
  name: 'HeaderMenuBrandsMobile',
  mixins: [respUtility, headerMenuOverlayUtility],
  props: {
    /** @prop {Object} [:brands="brandsInfo"] - gql type MenuQueries,
     * query BrandsMenu - featuredBrands and indexBrands */
    brands: {
      type: Object,
    },
    isPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /** change ref name for the onFocusOut (headerMenuOverlayUtility) function */
      nodeId: 'header-brands',
      tab: 0,
      tabs: [
        {
          tabName: 'All Brands',
          title: 'Brands',
          value: 'brands',
        },
        {
          tabName: 'Luxury Brands',
          title: 'Luxury Brands',
          value: 'luxuryBrands',
        },
      ],
    };
  },
  computed: {
    currentTab() {
      return this.tabs[this.tab] || this.tabs[0];
    },
  },
  methods: {
    setTab(tab) {
      this.tab = tab;
    },
  },
};

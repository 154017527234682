// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".itemName_wi9jE{display:flex;flex-direction:column}.name_dLwqE{font-size:14px;font-weight:400;line-height:20px}.name_dLwqE.name_dLwqE{color:var(--primary-black)}.brand_MxGL3{font-size:1.2rem;line-height:2rem}@media only screen and (min-width:1264px){.cartItemName_yYJRM .name_dLwqE{font-size:1.6rem;line-height:2.2rem;margin:0 0 .4rem;position:relative;top:.1rem}.cartItemName_yYJRM .brand_MxGL3{margin:0 0 1.4rem}}.onsite_7Nlq5{flex-direction:row;justify-content:space-between}.title_LsKD0{font-family:\"FuturaBT-Heavy\",serif;font-size:1.6rem;line-height:1.6rem}@media only screen and (min-width:1264px){.title_LsKD0{font-size:1.9rem;line-height:normal}}.header_\\+vql3{font-size:1.9rem;font-weight:600;line-height:normal;margin-bottom:2px}@media only screen and (min-width:1264px){.header_\\+vql3{font-size:2.4rem;font-weight:400}}.plan_HRQrE{text-align:right}@media only screen and (min-width:1264px){.plan_HRQrE{font-size:1.9rem;line-height:normal}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemName": "itemName_wi9jE",
	"name": "name_dLwqE",
	"brand": "brand_MxGL3",
	"cartItemName": "cartItemName_yYJRM",
	"onsite": "onsite_7Nlq5",
	"title": "title_LsKD0",
	"header": "header_+vql3",
	"plan": "plan_HRQrE"
};
module.exports = ___CSS_LOADER_EXPORT___;

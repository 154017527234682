
import respUtility from '@/mixins/respUtility';

export default {
  name: 'AboutUsViewFooterMobile',
  mixins: [respUtility],
  components: {
    Logo: () => import('./Logo.vue'),
  },
  props: {
    footerData: {
      type: Object,
    },
    filteredMenus: {
      type: Array,
    },
    contactUsItem: {
      type: Object,
    },
  },
};


import respUtility from '@/mixins/respUtility';
import headerMenuOverlayUtility from '@/mixins/headerMenuOverlayUtility';
/**
 * The header's account menu
 * @category [Header, Menus]
 * @component
 * @example <caption>The menu usage (see code)</caption>
 * <HeaderAdditional v-if="isRespMdAndUp" :phone="phone" />
 */
export default {
  name: 'HeaderAccountWrapper',
  mixins: [respUtility, headerMenuOverlayUtility],
  props: {
    isMenuPermanent: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      nodeId: 'header-account',
    };
  },
  computed: {
    isMobile() {
      return this.$device.isMobileOrTablet || this.isTouchDevice;
    },
    menuContentClass() {
      return this.isMobile ? this.$style.accountMenu : this.$style.accountMenuDesktop;
    },
  },
  methods: {
    // Open menu device
    onClick() {
      if (this.isMobile) {
        this.menu = true;
      } else {
        window.location.href = `${window.location.origin}/customer/account`;
      }
    },
    /** Close the menu  */
    onClose() {
      this.menu = false;
    },
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title_aNnXS{font-size:1.8rem;font-weight:600;line-height:2.6rem;margin:0 0 3.2rem}.brandsLuxury_HTDfe{padding:3.2rem 0 0}.brandsLuxuryDesktop_4fM24{padding:2.4rem .8rem 0 3.2rem}.brandsLuxuryDesktop_4fM24 .brandsScroll_TEUnQ{height:calc(100% - 58px);overflow-y:auto;padding-right:1.6rem}.brandsLuxuryDesktop_4fM24 .brandsScroll_TEUnQ::-webkit-scrollbar{background-color:var(--gray-9);border-radius:.8rem;height:.8rem;width:.8rem}.brandsLuxuryDesktop_4fM24 .brandsScroll_TEUnQ::-webkit-scrollbar-thumb{background-color:var(--gray-4);border-radius:1.6rem}@media only screen and (min-width:1264px){.brandsLuxuryDesktop_4fM24 .brandsScroll_TEUnQ .brandsList_N\\+y8o{grid-template-columns:16.6rem 16.6rem}}.brandsList_N\\+y8o{display:grid;grid-gap:1.2rem;grid-template-columns:repeat(auto-fill,16.5rem)}.brandsList_N\\+y8o .item_vbjjX{align-items:center;background-color:#242424;border-radius:.2rem;display:flex;height:7.2rem;justify-content:center;overflow:hidden}.brandsList_N\\+y8o .item_vbjjX:last-child{margin-bottom:3.2rem}.brandsList_N\\+y8o .item_vbjjX:hover .image_E02kj{transform:scale(1.1)}.brandsList_N\\+y8o .item_vbjjX:focus-visible{outline:var(--accessibility-outline);outline-offset:-2px}.brandsList_N\\+y8o .image_E02kj{transition:transform .1s linear}.brandsList_N\\+y8o img{vertical-align:middle}.brandsPage_gQAd8.brandsLuxuryDesktop_4fM24{border-left:1px solid var(--gray-4);margin-top:2.4rem;padding:0 0 3.2rem 3.2rem}@media only screen and (min-width:768px){.brandsPage_gQAd8.brandsLuxuryDesktop_4fM24 .brandsList_N\\+y8o{grid-gap:1.4rem;grid-template-columns:18.5rem 18.5rem}.brandsPage_gQAd8.brandsLuxuryDesktop_4fM24 .brandsList_N\\+y8o .item_vbjjX{height:8rem}}.brandsPage_gQAd8 .title_aNnXS{font-size:2.4rem;line-height:2.6rem;margin-bottom:1.6rem}.brandsPage_gQAd8 .brandsScroll_TEUnQ{height:auto;padding-right:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title_aNnXS",
	"brandsLuxury": "brandsLuxury_HTDfe",
	"brandsLuxuryDesktop": "brandsLuxuryDesktop_4fM24",
	"brandsScroll": "brandsScroll_TEUnQ",
	"brandsList": "brandsList_N+y8o",
	"item": "item_vbjjX",
	"image": "image_E02kj",
	"brandsPage": "brandsPage_gQAd8"
};
module.exports = ___CSS_LOADER_EXPORT___;

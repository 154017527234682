import { onLoad } from '@/helpers/onLoad';

export default ({ $config, route, store }) => {
  const homePagePath = '/';
  const allFurniturePagePath = '/all-furniture';
  const reviewPath = '/reviews';
  const testimonialsPath = '/testimonials';
  // return if the current route is the home page
  if (route.path === homePagePath
      || route.path === allFurniturePagePath
      || route.path === reviewPath
      || route.path === testimonialsPath) {
    return;
  }

  const { affirm } = $config;
  if (!affirm?.paymentAffirmApiUrl) {
    return;
  }
  onLoad(() => {
    if (store.state.pageData.globalType === 'PARENT_CATEGORY') {
      return;
    }
    const scriptEle = document.createElement('script');
    scriptEle.setAttribute('src', affirm.paymentAffirmApiUrl);
    scriptEle.setAttribute('async', 'true');
    const scriptEle1 = document.createElement('script');
    scriptEle1.innerHTML = `
      window._affirm_config = window._affirm_config || {};
      window._affirm_config.public_api_key = "${affirm.apiKey}";
      window.affirmJsUrl = "${affirm.paymentAffirmApiUrl}";

    `;
    document.body.appendChild(scriptEle1);
    document.head.appendChild(scriptEle);
  });
};

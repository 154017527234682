
export default {
  name: 'BaseImage',
  props: {
    minHeight: {
      type: [Number, String],
      default: 0,
    },
    sources: {
      type: Array,
    },
    minWidth: {
      type: [Number, String],
      default: 0,
    },
    initialLoading: {
      type: Boolean,
      default: true,
    },
    preload: {
      type: Boolean,
      default: false,
    },
  },
  head() {
    if (!this.preload) {
      return {};
    }
    if (!(Array.isArray(this.sources) && this.sources.length)) {
      return {
        link: [
          {
            rel: 'preload',
            as: 'image',
            href: this.$attrs.src,
          },
        ],
      };
    }
    const result = {};
    result.link = this.sources.map((source) => ({
      rel: 'preload',
      as: 'image',
      media: source.media,
      imagesrcset: source.srcset,
    }));
    return result;
  },
  data() {
    return {
      loading: this.initialLoading,
    };
  },
  computed: {
    cssVariables() {
      return {
        '--image-min-width': Number.isNaN(this.minWidth) ? this.minWidth : `${this.minWidth}px`,
        '--image-min-height': Number.isNaN(this.minHeight) ? this.minHeight : `${this.minHeight}px`,
      };
    },
  },
  methods: {
    load() {
      this.loading = false;
      this.$emit('load');
    },
  },
};


export default {
  name: 'HeaderAdditionalLink',
  props: {
    link: {
      type: Object,
      default() { return {}; },
    },
  },
  methods: {
    followLink() {
      if (this.link.isInternal) {
        this.$router.push(`/${this.link.href}`);
        return;
      }
      window.location.href = `${window.location.origin}/${this.link.href}`;
    },
  },
};

import dayjs from 'dayjs'

import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(duration)

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}


export default {
  props: {
    headerAttrs: {
      type: Object,
      default() { return {}; },
    },
    contentAttrs: {
      type: Object,
      default() { return {}; },
    },
    nameIcon: {
      type: String,
    },
  },
};

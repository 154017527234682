
import respUtility from '~/mixins/respUtility';
import { mapState } from 'vuex';

export default {
  name: 'HeaderMain',
  mixins: [respUtility],
  props: {
    headerData: {
      type: Object,
      default() {
        return {};
      },
    },
    isMobileMenuOpen: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isMenuPermanent: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    ...mapState({
      /** @returns {Object} - global brands data from headerQueries */
      brands: (state) => state.brands,
    }),
    isMobile() {
      return this.$device.isMobileOrTablet || this.isTouchDevice;
    },
    isCheckoutPage() {
      return this.$route.name === 'onepage';
    },
  },
};

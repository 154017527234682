/* eslint-disable max-classes-per-file */
/* eslint-disable no-underscore-dangle */

import NVue from 'vue';

class PluginStore {
  constructor(data = {}) {
    this.storeVM = new NVue({ data });
  }

  get state() {
    return this.storeVM.$data;
  }
}

let _Vue;

function install(Vue) {
  if (install.installed && _Vue === Vue) {
    return;
  }
  install.installed = true;
  _Vue = Vue;

  const self = this;

  Vue.mixin({
    beforeCreate() {
      self.init(this);
    },
    destroyed() {
    },
  });

  Object.defineProperty(Vue.prototype, '$isTabbingMode', {
    get() { return self.store.state.isRunning; },
  });

  /**
    * Implement handler (for example, focus on the element) if Tabbing Mode is on
    * @param handler {Function} - something to need implement
    * @param onNextTick {Boolean} - execute on the next tick
    * @example
    * this.$implementOnTabbingMode(function () {
    *   if (this.$refs?.addBtn?.$el) {
    *     this.$refs.addBtn.$el.focus()
    *   }
    * })
  */
  Object.defineProperty(Vue.prototype, '$implementOnTabbingMode', {
    value(handler, onNextTick = false) {
      if (
        !this.$isTabbingMode
        || typeof handler === 'function'
        || typeof onNextTick === 'boolean'
      ) {
        return;
      }

      if (onNextTick) {
        this.$nextTick(handler);
        return;
      }

      handler();
    },
  });
}

class OneSbAccessibility {
  constructor() {
    this.app = null;
    this.store = new PluginStore({
      isRunning: false,
    });
  }

  init(app /* Vue component instance */) {
    // main app previously initialized
    // return as we don't need to set up new global listener
    if (this.app) {
      return;
    }

    this.app = app;

    const tabHandler = (e) => {
      const isTabButton = e.key === 'Tab';

      if (isTabButton) {
        this.store.state.isRunning = true;

        window.addEventListener('mousemove', () => {
          document.activeElement.blur();
          this.store.state.isRunning = false;
        }, {
          passive: true,
          once: true,
        });
      }
    };

    document.addEventListener('keydown', tabHandler, { passive: true });
  }
}

OneSbAccessibility.prototype.install = install;

NVue.use(new OneSbAccessibility());


import { mapState } from 'vuex';

export default {
  name: 'HeaderCartCoupon',
  props: {
    miniCart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggleTooltip: false,
    };
  },
  computed: {
    ...mapState({
      couponData: (state) => state.cart.cart.couponData,
    }),
  },
  methods: {
    handleClickOutside(event) {
      if (this.toggleTooltip) {
        const tooltip = this.$refs.couponTooltip;
        const parent = tooltip.parentNode;
        if (!(parent === event.target || parent.contains(event.target))) {
          this.toggleTooltip = false;
        }
      }
    },
  },
  mounted() {
    document.body.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.handleClickOutside);
  },
};

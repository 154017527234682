const initialState = () => ({
  activeStep: 0,
  totalItems: 0,
  summary: {},
  couponData: null,
  activeShippingAddress: {},
  addresses: [],
  shippingMethods: [],
  activeShippingMethods: [],
  paymentMethods: [],
  activePaymentMethod: {},
  activePaymentCard: null,
  newAccount: null,
  newAccountError: null,
  isNewAccountValid: true,
  activeBillingAddress: null,
  isCardInfoValid: false,
  regions: [],
  newCart: null,
  newCardValidation: true,
  cardType: '',
  paymentInfoCheckout: {},
});

export default {
  namespaced: true,
  state: initialState,
  getters: {
    cartDataForMutation({ activePaymentCard, newCart }) {
      let cart = activePaymentCard || {};
      if (Object.keys(cart).length) {
        cart = {
          method: 'BRAINTREE',
          ccExpMonth: cart.month.toString(),
          ccExpYear: cart.year.toString(),
          ccLast4: cart.lastFour.toString(),
          ccType: cart.typeCode,
          ccToken: cart.token,
        };
      }
      if (newCart) {
        cart = {
          method: 'BRAINTREE',
          ccNumber: newCart.ccNumber,
          ccExpMonth: newCart.ccExpMonth,
          ccExpYear: newCart.ccExpYear,
          ccName: newCart.ccName,
          ccType: newCart.ccType,
          nonce: newCart.nonce,
          captchaCode: newCart.captchaCode,
          storeInVault: true,
        };
      }
      return cart;
    },
  },
  mutations: {
    setActiveStep(state, activeStep) {
      state.activeStep = activeStep;
    },
    resetState(state) {
      Object.assign(state, initialState());
    },
    setTotalItems(state, items) {
      state.totalItems = items;
    },
    setRegions(state, regions) {
      state.regions = regions;
    },
    setAddress(state, address) {
      if (Array.isArray(address)) {
        state.addresses = address;
      } else {
        state.addresses = [address];
      }
    },
    setActiveShippingAddress(state, address) {
      state.activeShippingAddress = address;
    },
    setShippingMethods(state, methods) {
      state.shippingMethods = methods;
    },
    setActiveShippingMethods(state, methods) {
      state.activeShippingMethods = methods;
    },
    setSummary(state, summary) {
      state.summary = summary;
    },
    setCouponData(state, couponData) {
      state.couponData = couponData;
    },
    setPaymentMethods(state, methods) {
      state.paymentMethods = methods;
    },
    setActivePaymentMethod(state, method) {
      state.activePaymentMethod = method;
    },
    setActivePaymentCard(state, card) {
      state.activePaymentCard = card;
    },
    setActiveBillingAddress(state, address) {
      state.activeBillingAddress = address;
    },
    setIsCardInfoValid(state, isCardInfoValid) {
      state.isCardInfoValid = isCardInfoValid;
    },
    setIsNewAccountValid(state, status) {
      state.isNewAccountValid = status;
    },
    setNewCart(state, cart) {
      state.newCart = cart;
    },
    setCardType(state, type) {
      state.cardType = type;
    },
    setNewAccount(state, account) {
      state.newAccount = account;
    },
    setNewAccountError(state, error) {
      state.newAccountError = error;
    },
    setNewCardValidation(state, status) {
      state.newCardValidation = status;
    },
    setPaymentInfoCheckout(state, paymentInfoCheckout) {
      state.paymentInfoCheckout = paymentInfoCheckout;
    },
    addNewAddress(state, address) {
      const isAddressExist = state.addresses.some((addr) => addr.id === address.id);
      if (!isAddressExist) {
        state.addresses = [...state.addresses, address];
      }
    },
    deleteCard(state, token) {
      const method = state.paymentMethods.find((m) => m.code === 'braintree');
      method.cards = method.cards.filter((card) => card.token !== token);
    },
    deleteCardFromMethods(state, token) {
      const method = state.activePaymentMethod;
      method.cards = method.cards.filter((card) => card.token !== token);
    },
  },
  actions: {
    setCard({ commit, state }, card) {
      commit('setActivePaymentCard', card);
      const addresses = state.addresses || [];
      const fields = [
        'city',
        'firstName',
        'lastName',
        'postcode',
        'region',
        'streetOne',
      ];
      const fullAddress = card?.address
        ? addresses.find((a) => fields.every((f) => a[f] === card.address[f])) : null;
      const defaultAddress = addresses.find((a) => a.defaultBilling);
      let isFull = Boolean(fullAddress);
      let isFullDefault = Boolean(defaultAddress);
      let isFullActive = Boolean(state.activeShippingAddress);
      fields.forEach((f) => {
        if (isFull && !fullAddress[f]) {
          isFull = false;
        }
        if (isFullDefault && !defaultAddress[f]) {
          isFullDefault = false;
        }
        if (isFullActive && !state.activeShippingAddress[f]) {
          isFullActive = false;
        }
      });
      if (isFull) {
        commit('setActiveBillingAddress', fullAddress);
      } else if (isFullDefault) {
        commit('setActiveBillingAddress', defaultAddress);
      } else {
        commit('setActiveBillingAddress', isFullActive ? state.activeShippingAddress : null);
      }
    },
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".link_OGiSR{align-items:center;color:var(--primary-white)!important;display:flex;height:2.4rem}.link_OGiSR:hover{-webkit-text-decoration:underline;text-decoration:underline}.icon_-nOHf{flex:0 0 auto;height:2.4rem;width:2.4rem}.title_AA35V{font-size:1.6rem;line-height:2.2rem;margin-left:.8rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "link_OGiSR",
	"icon": "icon_-nOHf",
	"title": "title_AA35V"
};
module.exports = ___CSS_LOADER_EXPORT___;

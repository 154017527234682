
/**
 * The on site premium protection popup
 * @category [Dialogs, Onsite]
 * @component
 * @example <caption>Dialog usage (see code)</caption>
 * <ProtectionPlanPopupAdditionalContent @hide-additional="toggle"
 * />
 * @events ['show-additional', 'change-onsite']
 */
export default {
  name: 'ProtectionPlanPopupMainContent',
  data() {
    return {
      list: [
        {
          title: 'What does the extended protection plan cover?',
          list: [
            'Accidental stains and damage such as tears, rips, and spills',
            'Pet damage (accidental pet stains, damage from claws, teeth, and beaks)',
            'Accidental rips & tears, seam separation',
            'Damage from liquid marks & rings',
            'Chipped, dented & scratched surfaces',
            'Broken zippers or buttons',
            'Damaged mechanical elements',
            'Structural failures of frames and levers',
            'Burns and Candle Wax',
            'Warped Wood',
          ],
          content: '',
        },
        {
          title: 'What makes this protection plan different?',
          list: [
            '$0 deductible',
            'Quick and easy claim approval',
            '24/7 customer support',
            'Professional in-home service',
            'Easy reimbursements',
          ],
          content: '',
        },
        {
          title: 'What are the benefits of having a protection plan?',
          list: [],
          content: 'The extended protection plan provides peace of mind and financial protection for your furniture investment from accidental damage. This plan covers stains and damage not covered by the manufacturer’s warranty. Accidents happen. Whether it\'s a spill, a scratch, or any unexpected mishap, You deserve to relax and enjoy your furniture without any worry or stress.',
        },
        {
          title: 'What do I need to provide to file a claim?',
          list: [],
          content: 'When filing a claim, you will simply need to provide either your contract ID, email address or other identifying piece of information. Our claims team will follow up if any additional information and photos are needed.',
        },
        {
          title: 'Is there a deductible or any other fees?',
          list: [],
          content: 'There are no deductibles or additional costs.',
        },
        {
          title: 'What happens if I return or exchange my product?',
          list: [],
          content: 'Each Protection Plan is tied to the specific product it was purchased with. If you return your product, your protection plan will be canceled. If canceled within the merchant’s return window, you will receive a full refund for your Protection Plan, less any claims paid. When you Return outside of the merchant’s return window, your refund will be prorated, based on the time your plan was active, minus any claims paid.',
        },
        {
          title: 'Where can I see the full coverage terms?',
          list: [],
          content: '<a href="/guardian-protection-plan" target="_blank">Click here</a> for full Plan Details',
        },
      ],
    };
  },
  computed: {
    isBoutiquePage() {
      return this.$store.state.metaData?.addons?.head?.isBoutique || false;
    },
  },
};


import respUtility from '@/mixins/respUtility';

export default {
  name: 'HeaderWishListLink',
  mixins: [respUtility],
  data() {
    return {
      wishList: {
        title: 'Wish List',
        slug: '/wishlist',
        iconName: 'IconWishList-32x32',
      },
    };
  },
  methods: {
    followLink(slug) {
      window.location.href = `${window.location.origin}/${slug}`;
    },
  },
};

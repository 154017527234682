// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subscribeRow_ilceS{align-items:flex-start;display:flex;flex-direction:column;flex-wrap:wrap;gap:16px;justify-content:center;margin:0 auto;padding:32px 16px}.subscribeRow_ilceS .subscribeElement_cpC\\+0 .v-text-field__details{min-width:calc(100vw - 32px)}@media only screen and (min-width:600px){.subscribeRow_ilceS .subscribeElement_cpC\\+0 .v-text-field__details{min-width:0}}@media only screen and (min-width:768px){.subscribeRow_ilceS{align-items:flex-start;flex-direction:row;flex-wrap:nowrap;gap:24px;padding:32px 40px}}@media only screen and (min-width:1264px){.subscribeRow_ilceS{align-items:flex-start;flex-direction:row;gap:16px;padding:40px}}@media(min-width:1680px){.subscribeRow_ilceS{padding:40px 0}}.logo_qApFJ{align-items:center;display:flex;flex-shrink:0;gap:20px;margin-top:2px}@media only screen and (min-width:1264px){.logo_qApFJ{margin-top:-3px}}.title_d9rqZ{font-size:18px;font-weight:600;line-height:26px}@media only screen and (min-width:1264px){.title_d9rqZ{font-size:24px;line-height:30px}}.subtitle_sE4ib{font-size:14px;line-height:20px}@media only screen and (min-width:768px){.subtitle_sE4ib{font-size:12px}}@media only screen and (min-width:1264px){.subtitle_sE4ib{font-size:14px}}.subscribeElement_cpC\\+0{flex-grow:1;width:100%}@media only screen and (min-width:768px){.subscribeElement_cpC\\+0{flex-grow:0;width:auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subscribeRow": "subscribeRow_ilceS",
	"subscribeElement": "subscribeElement_cpC+0",
	"logo": "logo_qApFJ",
	"title": "title_d9rqZ",
	"subtitle": "subtitle_sE4ib"
};
module.exports = ___CSS_LOADER_EXPORT___;

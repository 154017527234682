
import respUtility from '~/mixins/respUtility';
import headerMenuOverlayUtility from '~/mixins/headerMenuOverlayUtility';
/**
 * The header's brand menu
 * @category [Header, Menus]
 * @component
 * @example <caption>The block usage (see code)</caption>
 * <HeaderMenuBrandsDesktop :brands="brands" />
 */
export default {
  name: 'HeaderMenuBrandsDesktop',
  mixins: [respUtility, headerMenuOverlayUtility],
  props: {
    /** @prop {Object} [:brands="brandsInfo"] - gql type MenuQueries,
     * query BrandsMenu - featuredBrands and indexBrands */
    brands: {
      type: Object,
    },
    isMenuPermanent: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      /** change ref name for the onFocusOut (headerMenuOverlayUtility) function */
      nodeId: 'header-brands',
    };
  },
};

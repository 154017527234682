
import respUtility from '~/mixins/respUtility';
/**
 * The footer's subscribe block.
 * @category footer
 * @component
 * @example <caption>FooterSubscribe usage (see code)</caption>
 * <FooterSubscribe />
 */
export default {
  name: 'FooterSubscribe',
  mixins: [respUtility],
  data: () => ({
    showPopup: false,
  }),
  methods: {
    /** Hide or show popup */
    changeShowPopup() {
      this.showPopup = !this.showPopup;
    },
  },
};


/**
 * The empty minicart's content
 * @category [Header, Cart]
 * @component
 * @example <caption>Block usage (see code)</caption>
 * <HeaderCartEmpty />
 */
export default {
  name: 'HeaderCartEmpty',
  computed: {
    customer() {
      return this.$store.state.customer?.me;
    },
  },
};

import { onLoad } from '@/helpers/onLoad';

export default ({ $config }) => {
  const { pixel } = $config;
  if (!(pixel?.pixelGeneralPixelEnable)) {
    return;
  }
  onLoad(() => {
    const scriptEle = document.createElement('script');
    scriptEle.innerHTML = `
      var _caq = _caq || [];
      (function () {
        var ca = document.createElement("script");
        ca.type = "text/javascript";
        ca.async = true;
        ca.id = "_casrc";
        ca.src = "//t.channeladvisor.com/v2/12031794.js";
        var ca_script = document.getElementsByTagName("script")[0];
        ca_script.parentNode.insertBefore(ca, ca_script);
      })();
    `;
    document.body.appendChild(scriptEle);
  });
};

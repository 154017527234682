
export default {
  name: 'BaseSelect',
  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default() { return {}; },
    },
    label: {
      type: String,
      default: 'Select',
    },
    nudgeTop: {
      type: Number,
    },
    selectClass: {
      type: String,
      default: '',
    },
    filtersSelect: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    activatorAttrs: {
      type: Object,
      default() { return {}; },
    },
    listAttrs: {
      type: Object,
      default() { return {}; },
    },
    showRemove: {
      type: Boolean,
      default() { return false; },
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: String,
      default: '',
    },
    menuClass: {
      type: String,
      default: '',
    },
    welcomeForm: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
    },
  },
  data() {
    return {
      isOpen: false,
      currentValue: this.value,
    };
  },
  methods: {
    select(item) {
      this.currentValue = item;
      this.$emit('select', item);
    },
    toggleSelect() {
      this.isOpen = !this.isOpen;
    },
    handleScroll() {
      this.isOpen = false;
    },
  },
  computed: {
    isBoutiquePage() {
      return this.$store.state.metaData?.addons?.head?.isBoutique || false;
    },
    formattedItems() {
      const items = this.items.map((item) => {
        let textItem = '';
        if (item.content !== undefined && item.content !== null) {
          textItem = item.content;
        } else if (item.text !== undefined && item.text !== null) {
          textItem = item.text;
        }
        return {
          value: item.value,
          text: textItem,
        };
      });
      return items;
    },
    computedAttach() {
      return this.attach || false;
    },
  },
  mounted() {
    const elements = document.querySelectorAll('.gray-scroll, .include_set_item_wrapper');
    elements.forEach((element) => {
      element.addEventListener('scroll', this.handleScroll);
    });
  },
  beforeDestroy() {
    const elements = document.querySelectorAll('.gray-scroll, .include_set_item_wrapper');
    elements.forEach((element) => {
      element.removeEventListener('scroll', this.handleScroll);
    });
  },
};

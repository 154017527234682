

export default {
  name: 'OnepageHeader',
  props: {
    headerData: {
      type: Object,
    },
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".widget_UMyjr{align-items:center;display:flex;grid-gap:7px;cursor:pointer}@media only screen and (min-width:1264px){.widget_UMyjr{padding:16px 24px 16px 0;position:relative;grid-gap:8px;z-index:2}.widget_UMyjr:hover:after{opacity:1}.widget_UMyjr:hover .text_\\+XW5h{color:var(--primary-main);-webkit-text-decoration:underline;text-decoration:underline}.widget_UMyjr:after{background:var(--primary-white);border-radius:.8rem;bottom:0;content:\"\";display:block;left:-1.6rem;opacity:0;position:absolute;right:0;top:0;z-index:-1}}.image_sbs0K{flex:0 0 auto;height:56px;width:70px}@media only screen and (min-width:1264px){.image_sbs0K{height:80px}.image_sbs0K,.image_sbs0K img{width:100px}}.text_\\+XW5h{color:var(--gray-0);font-size:12px;font-weight:600;line-height:18px;max-width:100px;width:100%}@media only screen and (min-width:1264px){.text_\\+XW5h{font-size:16.327px;line-height:24.49px;max-width:124px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widget": "widget_UMyjr",
	"text": "text_+XW5h",
	"image": "image_sbs0K"
};
module.exports = ___CSS_LOADER_EXPORT___;


import reviewLogo from '@/assets/reviews-trust-logo_v1.png';
import ekomiLogo from '@/assets/ekomi_logo_v1.png';
import respUtility from '@/mixins/respUtility';

export default {
  name: 'AboutUsViewReviewContainer',
  mixins: [respUtility],
  data() {
    return {
      isClient: false,
      reviewLogo,
      ekomiLogo,
    };
  },
  computed: {
    contextMenuAlertMessage() {
      const year = new Date().getFullYear();
      return (
        'Copying Prohibited by Law - This image and all included logos '
        + `are copyrighted by Shopper Approved © ${year}.`
      );
    },
  },
  mounted() {
    this.isClient = true;
    setTimeout(() => {
      const element = document.querySelector('div[style*="font-size: 0.9em;"]');
      if (element) {
        element.style.fontSize = '';
      }
    }, 500);
  },
  methods: {
    showContextMenuAlert() {
      alert(this.contextMenuAlertMessage); // eslint-disable-line no-alert
    },
    openShopperApproved(event) {
      if (process.client) {
        const e = navigator.appName !== 'Microsoft Internet Explorer' ? 'yes' : 'no';
        const n = window.screen.availHeight - 90;
        let r = 940;

        if (window.innerWidth < 1400) {
          r = 620;
        }

        window.open(
          'https://www.shopperapproved.com/reviews/1StopBedrooms.com',
          'shopperapproved',
          `location=${e},scrollbars=yes,width=${r},height=${n},menubar=no,toolbar=no`,
        );

        // Prevent the event's default behavior
        if (event.stopPropagation) {
          event.stopPropagation();
        }
      }
      return false;
    },
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".authMenu_6dk3R{background:var(--gray-8);padding:1.7rem 1.6rem 1.6rem}.authMenuList_xh0v2{display:flex;margin:0 -12px}.authLi_mijT4{line-height:1;padding:0 12px}.authLi_mijT4:not(:first-child){border-left:1px solid var(--gray-3)}.authLink_KjWab{color:var(--primary-main);display:inline-block;font-size:1.4rem;font-weight:400;line-height:2rem;text-transform:capitalize}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authMenu": "authMenu_6dk3R",
	"authMenuList": "authMenuList_xh0v2",
	"authLi": "authLi_mijT4",
	"authLink": "authLink_KjWab"
};
module.exports = ___CSS_LOADER_EXPORT___;

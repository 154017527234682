
// eslint-disable-next-line import/no-extraneous-dependencies
import Loader from '~/assets/animation/loader.json';

export default {
  name: 'LoadingBar',
  components: {
    lottie: () => import('vue-lottie/src/lottie.vue'),
  },
  data() {
    return {
      params: {
        options: { animationData: Loader },
        renderer: 'svg',
        loop: true,
        autoPlay: true,
        height: 213,
        width: 213,
      },
    };
  },
};

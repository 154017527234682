const initialCart = {
  cartNumber: '',
  items: [],
  summaryCount: 0,
  subtotal: 0,
  canApplyMsrp: false,
};

const state = () => ({
  cart: { ...initialCart },
  addingStatus: false,
  phone: {
    phone: '877-958-9203',
    slug: '8779589203',
  },
  newItems: [],
  couponTimerId: null,
  priceNotification: null,
});

export default {
  namespaced: true,
  state,
  mutations: {
    setCart(state, cart) {
      state.cart = { ...state.cart, ...(cart || initialCart) };
    },
    setPhone(state, phone) {
      state.phone = phone || {};
    },
    setPhonePriceTier(state, phonePriceTier) {
      state.phonePriceTier = phonePriceTier || {};
    },
    setAddingStatus(state, status) {
      state.addingStatus = status;
    },
    setNewItems(state, items) {
      state.newItems = items || [];
    },
    setTimeRemains(state, time) {
      state.cart.couponData.timeRemains = time;
    },
    setCouponData(state, data) {
      state.cart.couponData = data;
    },
    setTimerId(state, id) {
      state.couponTimerId = id;
    },
    setPriceNotification(state, priceNotification) {
      state.priceNotification = priceNotification;
    },
  },
  actions: {
    startTimer({ commit, state }) {
      if (!state.cart.couponData) return;
      if (state.couponTimerId) return;
      const timerId = setInterval(() => {
        if (state.cart?.couponData?.timeRemains > 0) {
          commit('setTimeRemains', state.cart.couponData.timeRemains - 1);
        } else {
          this.dispatch('cart/stopTimer');
        }
      }, 1000);
      commit('setTimerId', timerId);
    },
    stopTimer({ commit, state }) {
      clearInterval(state.couponTimerId);
      commit('setTimerId', null);
      commit('setCouponData', null);
    },
  },
  getters: {
    timeRemains: (state) => state.cart.couponData?.timeRemains,
  },
};

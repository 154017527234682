
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('cart', ['timeRemains']),
    formattedTime() {
      const hoursNumber = Math.floor(this.timeRemains / 3600);
      const hours = hoursNumber.toString().padStart(2, '0');
      const minutes = Math.floor((this.timeRemains % 3600) / 60).toString().padStart(2, '0');
      const seconds = (this.timeRemains % 60).toString().padStart(2, '0');
      if (hoursNumber) {
        return `${hours}:${minutes}:${seconds}`;
      }
      return `${minutes}:${seconds}`;
    },
  },
  methods: {
    ...mapActions('cart', ['startTimer']),
  },
  mounted() {
    this.startTimer();
  },
};

import gql from 'graphql-tag';

export const GET_ABOUT_US_DATA = gql`
  query getAboutUsData ($slug: String!) {
    cmsPage(slug: $slug){
      title
      banner{
        alt
        src
        sources{
          media
          srcset
        }
      }
    }
  },
`;

export const GET_ABOUT_US_HEADER = gql`
  query getHeaderData($slug: String!){
    cmsPage(slug: $slug){
      header{
        headerMenuItems{
          name
          slug
        }
      }
      footer{
        left{
          links{
            slug
            name
          }
          copyRight
        }
        right{
          link{
            name
            slug
          }
        }
        center{
          menu{
            identifier
            menuTitle
            menuLinks{
              name
              slug
            }
          }
        }
      }
    }
  },
`;

export const GET_WELCOME_PAGE_DATA = gql`
  query getWelcomePageData($slug: String!) {
    cmsPage(slug: $slug) {
      roleOptions {
        field
        options {
          text
          value
        }
      }
      productCountOptions {
        field
        options {
          text
          value
        }
      }
      productCategoryOptions {
        field
        options {
          text
          value
        }
      }
    }
  },
`;


/**
 * Alerts' list displays alerts from the store
 * @category Alerts
 * @example <caption>Alert usage (see code)</caption>
 * <AlertsList />
 * */
export default {
  name: 'AlertsList',
  data() {
    return {
      alertBodyClass: 'alertIsShowed',
    };
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
  methods: {
    /** Emit the event 'close-message' */
    onCloseMsg(msg) {
      this.$emit('close-message', msg);
    },
  },
  mounted() {
    document.body.classList.add(this.alertBodyClass);
  },
  beforeDestroy() {
    document.body.classList.remove(this.alertBodyClass);
  },
};

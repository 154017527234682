
import headerMenuOverlayUtility from '@/mixins/headerMenuOverlayUtility';
/**
 * The header's main menu (mobile version)
 * @category [Header, Menus]
 * @component
 * @example <caption>The block usage (see code)</caption>
 * <HeaderMenuMobile :phone="phone" :items="items" :brands="brands" />
 * @events ['toggle-mobile-menu']
 */
export default {
  name: 'HeaderMenuMobile',
  mixins: [headerMenuOverlayUtility],
  props: {
    /** @prop {Object} [:items="menu"] - The categories menu data - gql [TreeMenuItemType]! */
    categories: {
      type: Object,
    },
    /** @prop {Object} [:brands="indexBrands"] -
     * The index brands menu data (for mobile version) - gql [BrandsMenuType]! */
    brands: {
      type: Object,
    },
  },
  data() {
    return {
      /** change ref name for the onFocusOut (headerMenuOverlayUtility) function */
      nodeId: 'header-menu-mobile',
      isMounted: false,
      closeOnClick: false,
      isChildActive: false,
    };
  },
  watch: {
    menu(newValue) {
      this.$emit('toggle-mobile-menu', newValue);
    },
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    items() {
      return this.categories?.subs?.filter((item) => item.name !== 'Clearance');
    },
    brandsArray() {
      return [this.brands?.alpha, this.brands?.luxury];
    },
    customer() {
      return this.$store.state.customer;
    },
    vDialogContentClasses() {
      const classes = [
        this.$style.mobileMenuWrapper,
      ];

      if (this.isChildActive) {
        classes.push(this.$style.isDisabled);
      }

      return classes;
    },
  },
  methods: {
    closeDialog() {
      this.toggleMenu();
    },
    changeStatusChild(status) {
      this.isChildActive = status;
    },
    followLink(slug) {
      window.location.href = `${window.location.origin}/${slug}`;
      this.$nextTick(() => {
        this.closeDialog();
      });
    },
  },
};


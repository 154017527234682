import respUtility from '@/mixins/respUtility';

export default {
  mixins: [respUtility],
  data() {
    return {
      menu: false,
      /** {String} - it's a parent node id - change it in the data of your component */
      nodeId: 'wrapper',
      /** {String} - it's a parent node selector - use it for the 'attach' prop of your v-menu  */
      nodeSelector: false,
      /** {Boolean} - change this value if this menu doesn't change the overlay status */
      changeOverlay: true,
      /** {Boolean} - Designates if menu should close on outside-activator click */
      closeOnClick: true,
      permanent: false,
      menuSeoMobile: true,
    };
  },
  /** Compute nodeSelector */
  mounted() {
    this.nodeSelector = `#${this.nodeId}`;
    if (this.menu) {
      this.menu = false;
    }
  },
  watch: {
    /** Change overlay status */
    menu(newStatus) {
      if (this.permanent && !newStatus) {
        this.permanent = false;
        this.$nuxt.$emit('change-global-permanent', false);
      }
      if (!this.changeOverlay) {
        return;
      }
      if (newStatus || (!this.currentMenu || this.currentMenu === this.nodeId)) {
        setTimeout(() => {
          this.$nuxt.$emit('change-global-overlay', newStatus);
          this.$nuxt.$emit('change-header-scrolling', !newStatus);
          this.$nuxt.$emit('minicart-open', newStatus);
        }, 10);
      }
      if (newStatus) {
        this.$store.commit('setCurrentMenu', this.nodeId);
      } else if (this.currentMenu === this.nodeId) {
        this.$store.commit('setCurrentMenu', '');
      }
    },
    currentMenu(newStatus) {
      if (this.currentMenu && this.currentMenu !== 'search_mini_form') {
        if (this.$refs && this.$refs.inputSearch) {
          this.$refs.inputSearch.$refs.input.blur();
        }
      }
      if (newStatus && this.currentMenu !== this.nodeId) {
        this.menu = false;
      }
    },
    /** Hide the menu on changing mobile/desktop status */
    isRespLgAndUp() {
      if (this.menu) {
        this.menu = false;
      }
    },
    /** Hide the menu on routing */
    '$route.fullPath': function watchPath() {
      if (this.menu) {
        this.menu = false;
      }
    },
  },
  computed: {
    currentMenu() {
      return this.$store.state.currentMenu;
    },
  },
  /** Remove the overlay */
  beforeDestroy() {
    if (!this.changeOverlay) {
      return;
    }
    if (this.menu) {
      this.$nuxt.$emit('change-global-overlay', false);
      this.$nuxt.$emit('change-header-scrolling', true);
    }
  },
  methods: {
    /** This method checks is the current element into the parent node (nodeId) and turn off the menu if it isn't.
         * this.nodeId - it's a parent node id - change it in the data of your component
         * @example <caption>Use it for the parent node</caption>
         * <div :id="nodeId" @focusout="onFocusOut">
         */
    onFocusOut(e) {
      if (this.nodeId && e) {
        if (!(this.$device.isMobileOrTablet || this.isTouchDevice)
            && this.nodeId === 'search_mini_form' && !e?.relatedTarget?.closest(`#${this.nodeId}`)) {
          this.menu = false;
        }
        const node = e.relatedTarget ? e.relatedTarget : e.target;
        if (node) {
          if (!node.closest(`#${this.nodeId}`)) {
            this.menu = false;
          }
          return;
        }
      }
      if (this.closeOnClick) {
        this.menu = false;
      }
    },
    /** Toggle the mobile header's main menu / submenu
         * this.$refs.btn - use ref 'btn' for the menu's activator
         * @example <caption>Use it on open/close element's</caption>
         * <v-btn ref="btn" @click="toggleMenu" @focus="toggleMenu">Back</v-btn>
         */
    toggleMenu(e) {
      if (this.$refs && this.$refs.btn) {
        this.$refs.btn.$el.blur();
      }
      if (e && e.type === 'focus' && !this.$isTabbingMode) {
        return;
      }
      this.menu = !this.menu;
      if (this.menu) {
        this.menuSeoMobile = false;
      }
    },
    toggleMenuDesktop() {
      this.permanent = !this.permanent; // Setting the flag that a click was made
      this.$nuxt.$emit('change-global-permanent', this.permanent); // Setting the globalflag that a click was made
      if (this.permanent && !this.menu) {
        this.menu = true;
      }
    },
    openMenuDesktop() {
      if (!this.permanent) {
        this.menu = true; // Open menu only if there was no click
      }
    },
    closeMenuDesktop(e) {
      if (this.$refs.closeMenu) {
        if ((this.permanent && e.type === 'click') || !this.permanent) {
          this.menu = false;
        }
      } else if (!this.permanent) {
        this.menu = false; // Close the menu only if there was no click
      }
    },
  },
};


import { validationMixin } from 'vuelidate';
import respUtility from '@/mixins/respUtility';
import validationUtility, { emailValidation } from '@/mixins/validationUtility';
import { SUBSCRIBE } from '@/api/apolloHeaderFooterMutations';

/**
 * The footer's subscribe form.
 * @category Subscribe
 * @component
 * @example <caption>SubscribeForm usage (see code)</caption>
 * <SubscribeForm @success="onSuccess" />
 * @events ['success']
 * @nuxt-events ['change-global-loading']
 */
export default {
  name: 'SubscribeForm',
  mixins: [respUtility, validationMixin, validationUtility],
  props: {
    buttonAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    valid: false,
    subscriptionEmail: '',
    message: [],
    additionalErrors: [],
    defaultButtonAttr: {
      secondary: true,
      large: true,
      type: 'submit',
    },
  }),
  validations: {
    subscriptionEmail: emailValidation(),
  },
  computed: {
    emailErrors() {
      const input = this.$v?.subscriptionEmail;
      return this.validateEmail(input);
    },
    errors() {
      return [...this.emailErrors, ...this.additionalErrors];
    },
  },
  methods: {
    clearMessages() {
      if (this.additionalErrors.length) {
        this.additionalErrors = [];
      }
      if (this.message.length) {
        this.message = [];
      }
    },
    changeInput() {
      this.clearMessages();
      this.$v.$touch();
    },
    /** Show popup or error message, set the 'newsletter_subscribed' timestamp to the Local Storage */
    async onSubscribe() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$nuxt.$emit('change-global-loading', true);
        try {
          const { footerMutations: { subscribe } } = await this.$graphql.default
            .request(SUBSCRIBE, { email: this.subscriptionEmail }) || {};
          this.clearMessages();
          this.$store.commit('addMessage', {
            type: subscribe?.success ? 'success' : 'error',
            content: subscribe?.message,
          });
          if (subscribe?.success) {
            this.$refs.form.reset();
            this.$v.$reset();
            // localStorage.setItem('newsletter_muted', 'true');
            this.$emit('success');
          } else {
            this.$nextTick(() => {
              this.$v.$touch();
            });
          }
        } catch (error) {
          console.error(error);
          this.$store.commit('addMessage', { type: 'error', content: 'Subscribe was failed.' });
          this.$nextTick(() => {
            this.$v.$touch();
          });
        }
        this.$nuxt.$emit('change-global-loading', false);
      }
    },
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo_n-8N2{display:block;margin-left:6px;max-width:166px}@media only screen and (min-width:1264px){.logo_n-8N2{margin-left:0;max-width:none}}.image_uED7D{display:block;height:auto;max-width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "logo_n-8N2",
	"image": "image_uED7D"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu_eSNqQ{display:grid;grid-gap:24px;flex-grow:1}@media only screen and (min-width:1264px){.menu_eSNqQ{grid-gap:16px}}.menu_eSNqQ .link_DbyJK{color:var(--primary-black);font-size:14px;line-height:20px}.menu_eSNqQ .link_DbyJK:hover{color:var(--primary-main);-webkit-text-decoration:underline;text-decoration:underline}.title_i26aZ{font-size:18px;font-weight:600;line-height:26px}@media only screen and (min-width:1264px){.title_i26aZ{font-size:16px;line-height:22px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "menu_eSNqQ",
	"link": "link_DbyJK",
	"title": "title_i26aZ"
};
module.exports = ___CSS_LOADER_EXPORT___;



import authMixin from '@/mixins/authMixin';

export default {
  name: 'HeaderAccountButton',
  props: {
    customer: {
      type: Object,
    },
  },
  mixins: [authMixin],
  methods: {
    followLink(slug) {
      window.location.href = `${window.location.origin}/${slug}`;
      this.$nextTick(() => {
        this.$emit('close');
      });
    },
    singOut() {
      this.$emit('close');
      this.logOut();
    },
  },
};

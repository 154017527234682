// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

export const UPDATE_CART_PROMO_CODE = gql`
    mutation updateCartPromoCode($remove: Boolean!, $code: String) {
        cart {
            promoCode(remove: $remove, code: $code) {
                message
                success
            }
        }
    }
`;

export const UPDATE_ITEM_PROTECTION = gql`
    mutation updateItemProtection($protectionPlan: [ProtectionPlanInput]) {
        cart {
            update {
                protection(protectionPlan: $protectionPlan) {
                    message
                    success
                }
            }
        }
    }
`;

export const UPDATE_CART_ITEM = gql`
    mutation updateCartItem($item: CartItemInput) {
        cart {
            update {
                item(item: $item) {
                    message
                    success
                }
            }
        }
    }
`;

export const REMOVE_CART_ITEM = gql`
    mutation removeCartItem($itemId: ID!) {
        cart {
            remove {
                item(id: $itemId) {
                    message
                    success
                }
            }
        }
    }
`;

export const ADD_CART_ITEM = gql`
    mutation addCartItem(
        $product: ID!,
        $qty: Int!,
        $freightRelatedProduct: ID,
        $relatedProduct: [RelatedProductInput],
        $setSelection: Int,
        $bundleOption: [BundleOptionInput],
        $foundation: CartItemInput,
        $mattress: MattressInput,
        $protectionPlan: [ProtectionPlanInput],
        $customOptions: [CustomOptionInput]
        $productTypeId: ProductTypeId
        $zipcode: String
        ) {
        cart {
            add {
                item(
                    product: $product,
                    qty: $qty,
                    freightRelatedProduct: $freightRelatedProduct,
                    relatedProduct: $relatedProduct,
                    setSelection: $setSelection,
                    bundleOption: $bundleOption,
                    foundation: $foundation,
                    mattress: $mattress,
                    protectionPlan: $protectionPlan,
                    customOptions: $customOptions,
                    productTypeId: $productTypeId
                    zipcode: $zipcode
                    ) {
                    message
                    success
                    unBxdData {
                        webId
                        qty
                        price
                    }
                }
            }
        }
    }
`;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error_tjlxj{padding:24px 0}@media only screen and (min-width:1264px){.error_tjlxj{padding:97px 0}}.block_Peu6J{color:var(--primary-black);margin:0 auto;max-width:816px;width:100%}.image_g9Y1N{align-items:center;display:flex;height:166px;margin:0 auto 24px;overflow:hidden;width:200px}@media only screen and (min-width:1264px){.image_g9Y1N{height:340px;margin:0 auto 8px;width:400px}}.image_g9Y1N img{display:block;width:100%}.title_UeCuG{font-size:24px;font-weight:600;line-height:30px;margin:0 0 8px;text-align:center}@media only screen and (min-width:1264px){.title_UeCuG{font-size:40px;line-height:48px}}.text_pW0k8{font-size:16px;line-height:22px;margin:0 auto;max-width:502px;padding:0 8px;text-align:center;width:100%}@media only screen and (min-width:1264px){.text_pW0k8{font-size:18px;line-height:26px;padding:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "error_tjlxj",
	"block": "block_Peu6J",
	"image": "image_g9Y1N",
	"title": "title_UeCuG",
	"text": "text_pW0k8"
};
module.exports = ___CSS_LOADER_EXPORT___;

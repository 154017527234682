import { onDemand } from '@/helpers/onDemand';

export default ({ $config }, inject) => {
  const { syteApi } = $config;
  if (!syteApi.sytFtpJsIntegrationEnable || !syteApi.sytFtpJsIntegrationScriptTagEnable) {
    return;
  }
  // eslint-disable-next-line new-cap
  const loader = new onDemand(
    // eslint-disable-next-line max-len,vue/max-len
    `//cdn.syteapi.com/assets/imajs/imajs.js?a=${syteApi.sytFtpJsIntegrationAccountId}&sig=${syteApi.sytFtpJsIntegrationApiKey}&languageCode=en_US`,
  );

  inject('syteai', {
    init(callback) {
      loader.load(callback);
    },
  });
};

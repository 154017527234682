// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';

export const SUBSCRIBE = gql`
    mutation subscribe ($email: String!) {
        footerMutations {
            subscribe(email: $email) {
              success
              message
            }
        }
    },
`;

export const UPDATE_CART_ITEM = gql`
    mutation updateCartItem($item: CartItemInput) {
        cart {
            update {
                item(item: $item) {
                    message
                    success
                }
            }
        }
    }
`;

export const REMOVE_CART_ITEM = gql`
    mutation removeCartItem($itemId: ID!) {
        cart {
            remove {
                item(id: $itemId) {
                    message
                    success
                }
            }
        }
    }
`;

export const LOGOUT = gql`
    mutation Logout {
        customer {
            logout {
                message
                success
            }
        }
    }
`;

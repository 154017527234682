
export default {
  name: 'HeaderMenuMobileLink',
  props: {
    link: {
      type: Object,
      default() { return {}; },
    },
  },
  methods: {
    followLink() {
      window.location.href = `${this.link.href}`;
      this.$nextTick(() => {
        this.$emit('close');
      });
    },
  },
};

export function debounce(fn, delay = 300) {
  let timeoutID = null;
  return function d(...args) {
    clearTimeout(timeoutID);
    const that = this;
    timeoutID = setTimeout(() => {
      fn.apply(that, args);
    }, delay);
  };
}

export function decodeText(str) {
  const parser = new DOMParser();
  const txt = parser.parseFromString(str, 'text/html');
  return txt.documentElement.textContent;
}
export function htmlspecialcharsDecode(str, quoteStyleParam) {
  //        example 1: htmlspecialcharsDecode("<p>this -&gt; &quot;</p>", 'ENT_NOQUOTES')
  //        returns 1: '<p>this -> &quot;</p>'
  //        example 2: htmlspecialcharsDecode("&amp;quot;")
  //        returns 2: '&quot;'
  let quoteStyle = quoteStyleParam;
  let optTemp = 0;
  let i = 0;
  let noquotes = false;
  if (typeof quoteStyle === 'undefined') {
    quoteStyle = 2;
  }
  let string = str.toString()
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');
  const OPTS = {
    ENT_NOQUOTES: 0,
    ENT_HTML_QUOTE_SINGLE: 1,
    ENT_HTML_QUOTE_DOUBLE: 2,
    ENT_COMPAT: 2,
    ENT_QUOTES: 3,
    ENT_IGNORE: 4,
  };
  if (quoteStyle === 0) {
    noquotes = true;
  }
  if (typeof quoteStyle !== 'number') {
    // Allow for a single string or an array of string flags
    quoteStyle = [].concat(quoteStyle);
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < quoteStyle.length; i++) {
      // Resolve string input to bitwise e.g. 'PATHINFO_EXTENSION' becomes 4
      if (OPTS[quoteStyle[i]] === 0) {
        noquotes = true;
      } else if (OPTS[quoteStyle[i]]) {
        // eslint-disable-next-line no-bitwise
        optTemp |= OPTS[quoteStyle[i]];
      }
    }
    quoteStyle = optTemp;
  }
  // eslint-disable-next-line no-bitwise
  if (quoteStyle & OPTS.ENT_HTML_QUOTE_SINGLE) {
    // PHP doesn't currently escape if more than one 0, but it should:
    string = string.replace(/&#0*39;/g, "'");
    // This would also be useful here, but not a part of PHP:
    // string = string.replace(/&apos;|&#x0*27;/g, "'");
  }
  if (!noquotes) {
    string = string.replace(/&quot;/g, '"');
  }
  // Put this in last place to avoid escape being double-decoded
  string = string.replace(/&amp;/g, '&');
  return string;
}

export function copyData(data, method = 'json') {
  if (method === 'json') {
    const str = JSON.stringify({ value: data });
    const { value } = JSON.parse(str);

    return value;
  }

  return null;
}

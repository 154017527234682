
function isNumber(sorceValue) {
  const value = parseFloat(sorceValue);
  return !Number.isNaN(value) && (typeof value === 'number');
}

function svgViewBoxValidator(sorceValue) {
  const regex = /\d+ \d+ \d+ \d+/g;
  const [result = null] = sorceValue.match(regex) ?? [];
  return typeof result === 'string';
}

function nameValidator(sorceValue) {
  return sorceValue.trim() !== '';
}

function getDataFromName() {
  const [
    name,
    iconName,
    width,
    height,
  ] = this.name.match(/^([\w\-\\_]+)-(\d+)x(\d+)$/) || this.name.match(/^([\w\-\\_]+)$/);

  return {
    name,
    iconName,
    ...(width ? { width: Number(width) } : {}),
    ...(height ? { height: Number(height) } : {}),
    oldStyleName: name === iconName,
    hasDimensions: Number(width) > 0 && Number(height) > 0,
    hasAnyDimensions: Number(width) > 0 || Number(height) > 0,
  };
}

export default {
  name: 'BaseIcon',
  props: {
    /** @prop {string} name - Icon name (from svg library) */
    name: {
      type: String,
      required: true,
      validator: nameValidator,
    },
    /**
     * @prop {string} [labelledby] -  An accessible name. Each accessible name is Id.
     * The "labeledby" field can consist of one or more space-separated Ids.
     * https://developer.mozilla.org/en-US/docs/web/accessibility/aria/attributes/aria-labelledby
     */
    labelledby: {
      type: String,
      validator: nameValidator,
    },
    /** @prop {string} [title] - Title - is Icon description using for accessibility */
    title: {
      type: String,
    },
    /**
     * @prop {Boolean} [is-title="false"] - If this flag is present, Icon description (default or custom) is rendered
     */
    isTitle: {
      type: Boolean,
      default: false,
    },
    /** @prop {string} [color] - Icon color. By default, the icon inherits the color of the text */
    color: {
      type: String,
    },
    /** @prop {string|number} [width] - Icon width */
    width: {
      type: [String, Number],
      validator: isNumber,
    },
    /** @prop {string|number} [height] - Icon height */
    height: {
      type: [String, Number],
      validator: isNumber,
    },
    /** @prop {string} [viewBox] - viewBox attribute value of SVG */
    viewBox: {
      type: String,
      validator: svgViewBoxValidator,
    },
    /**
     * @prop {Boolean} [isAdaptive=false] - If this flag is present, "width" and "height" are not set,
     * and the SVG is expanded proportionately to its parent's bounds.
     */
    isAdaptive: {
      type: Boolean,
      default: false,
    },
    /**
     * @prop {string} [useFontSizeToFormSides] - The parameter sets the dimensions of the SVG to "em".
     * Also determines the aspect ratio of the icon.
     * - equal - The sides are the same.
     * - proportional - The aspect ratio is preserved according to the "viewBox" value.
     * - proportionalAndBasedOnInputDimensions - The aspect ratio is preserved
     *   according to the passed "width" and "height" values.
     */
    useFontSizeToFormSides: {
      type: String,
      validator(sorceValue) {
        return [
          'equal',
          'proportional',
          'proportionalAndBasedOnInputDimensions',
        ].includes(sorceValue);
      },
    },
  },
  computed: {
    dataFromName: getDataFromName,

    hasWidth() {
      return Number(this.width) > 0;
    },
    hasHeight() {
      return Number(this.height) > 0;
    },
    hasViewBox() {
      return typeof this.viewBox === 'string';
    },

    finalViewBox() {
      return this.hasViewBox
        ? this.viewBox
        : this.getViewBoxFromName();
    },

    dimensions() {
      if (this.useFontSizeToFormSides === 'equal') {
        return {
          width: '1em',
          height: '1em',
        };
      }

      if (this.useFontSizeToFormSides === 'proportional') {
        const dimensions = this.getDimensionsFromViewBox(this.finalViewBox);

        return {
          width: `${dimensions.width / dimensions.height}em`,
          height: '1em',
        };
      }

      if (
        this.useFontSizeToFormSides === 'proportionalAndBasedOnInputDimensions'
          && this.hasWidth
          && this.hasHeight
      ) {
        return {
          width: `${this.width / this.height}em`,
          height: '1em',
        };
      }

      if (this.isAdaptive) {
        return {};
      }

      if (!this.hasWidth && !this.hasHeight) {
        return this.getDimensionsFromViewBox(this.finalViewBox);
      }

      const dimensions = {};

      if (this.hasWidth) {
        dimensions.width = Number(this.width);
      }

      if (this.hasHeight) {
        dimensions.height = Number(this.height);
      }

      return dimensions;
    },

    labelledbyString() {
      return `${this.name} ${this.labelledby || ''}`.trim();
    },

    defaultTitle() {
      const sentence = this.pascalCaseToSentenceCase(this.dataFromName.iconName);
      return `${sentence} icon`;
    },

    titleString() {
      return this.title || this.defaultTitle;
    },

    style() {
      let styleString = '';

      if (this.color) {
        styleString += `--icon-color: ${this.color};`;
      }
      return styleString;
    },
  },
  methods: {
    isNumber,

    getViewBoxFromName() {
      const {
        width,
        height,
        hasDimensions,
      } = this.dataFromName;

      if (hasDimensions) {
        return `0 0 ${width} ${height}`;
      }

      throw new Error('It is not possible to form a viewBox string based on the component name.');
    },

    getDimensionsFromViewBox(viewBox) {
      const [
        width,
        height,
      ] = viewBox.split(' ').slice(2).map((str) => Number(str));

      return {
        width,
        height,
      };
    },

    pascalCaseToSentenceCase(str) {
      const result = str.replace(/([A-Z])/g, ' $1');
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
  },
};


export default {
  name: 'FooterCopyright',
  data() {
    return {
      year: '',
    };
  },
  created() {
    this.year = new Date().getFullYear();
  },
  computed: {
    isCheckoutPage() {
      return this.$route.name === 'onepage';
    },
  },
};


/**
 * The link with arrow icon.
 * @category Links
 * @component
 * @example <caption>Link usage (see code)</caption>
 * <LinkWithArrow to="/brands">
 *   View all
 * </LinkWithArrow>
 */
export default {
  name: 'LinkWithArrow',
  props: {
    /** @prop {String} [:icon-position="'left'"] - set icon position and direction */
    iconPosition: {
      type: String,
      default: 'right',
    },
  },
  computed: {
    isBoutiquePage() {
      return this.$store.state.metaData?.addons?.head?.isBoutique || false;
    },
  },
};


import logoSrc from '@/assets/logo-238x80.svg';

export default {
  name: 'FooterNavigationLogo',
  data() {
    return {
      logoSrc,
    };
  },
};


export default {
  name: 'HeaderMainLogo',
  props: {
    simple: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    isBoutiquePage() {
      return this.$store.state.metaData?.addons?.head?.isBoutique || false;
    },
    logoImgName() {
      return this.isBoutiquePage ? 'logo-gold-167x57' : 'logo-white-167x57';
    },
  },
};

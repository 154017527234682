
/**
 * The content of the header's brand menu
 * @category [Header, Menus]
 * @component
 * @example <caption>The block usage (see code)</caption>
 * <BrandCatalogIndex :brand-index="brands" />
 * @events ['go-to-letter' - payload {Object} gql BrandsMenuType]
 */
export default {
  name: 'HeaderMenuBrandsCatalogIndex',
  props: {
    /** @prop {Array} [:brand-index="brandIndex"] -
     * The index brands menu data (for mobile version) - gql [BrandsMenuType]! */
    brandIndex: {
      type: Array,
      required: true,
    },
    isMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeLetter: null,
      alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    };
  },
  computed: {
    fullBrandIndex() {
      return this.alphabet.map((letter) => {
        const item = this.brandIndex.find((i) => letter === i.letter);
        return item || { letter, brands: [] };
      });
    },
  },
  methods: {
    /**
     * Emit 'go-to-letter' event with payload gql BrandsMenuType and set new active letter
     * @param letter {Object} - gql BrandsMenuType
     */
    goToLetter(letter) {
      this.$emit('go-to-letter', letter);
      this.activeLetter = letter;
    },
    /** Reset active letter status */
    clearLetterClass(l) {
      if (l && this.activeLetter && this.activeLetter.letter === l.letter) {
        this.activeLetter = null;
      }
    },
  },
};


import respUtility from '@/mixins/respUtility';
import strUtility from '@/mixins/strUtility';
/**
 * Component to build Base Select with Int Values
 * @category Forms
 * @subcategory Select
 * @component
 * @example <caption>Select usage (see code)</caption>
 * <SelectQuantity :quantity="5" :min="2" :max="6" />
 */
export default {
  name: 'FormsSelectQuantity',
  mixins: [respUtility, strUtility],
  props: {
    /** @prop {Number} [:quantity="2"] - selected quantity */
    quantity: {
      type: Number,
      default: 1,
    },
    /** @prop {Number} [:min="2"] - minimal quantity value */
    min: {
      type: Number,
      default: 1,
    },
    /** @prop {Number} [:max="2"] - maximum quantity value */
    max: {
      type: Number,
      default: 11,
    },
    clearance: {
      type: Object,
    },
    showRemove: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /** Control quantity select, onchange emit 'set-qty' event with quantity arg */
    qty: {
      get() {
        return this.quantity;
      },
      set(newValue) {
        this.$emit('set-qty', newValue);
      },
    },
    defaultProps() {
      return {
        label: (this.$device.isMobileOrTablet || this.isTouchDevice) ? 'Qty' : '',
        solo: !(this.$device.isMobileOrTablet || this.isTouchDevice),
        'hide-details': true,
        items: this.qtyItems,
        'max-height': this.qtyItems.length * 50,
        'nudge-top': 0,
        'nudge-bottom': 0,
        'z-index': 204,
        'content-class': 'base-select-menu',
        'allow-overflow': true,
        'show-remove': this.showRemove,
        'activator-attrs': { 'data-id': 'c0-q1' },
        'list-attrs': { 'data-id': 'c0-q2' },
      };
    },
    qtyItems() {
      const values = [];
      for (let i = Math.floor(this.min); i < Math.floor(this.max);) {
        const item = {
          value: i,
        };
        if (this.clearance && (i !== 0 || !this.showRemove)) {
          item.content = `
            <div class="each-product-wrapper">
              <span class="each-product-count">${i}</span>
              <span class="each-product-price">${this.formatPrice(this.eachPrice(i))} ea.</span>
            </div>
          `;
        } else if (i === 0 && this.showRemove) {
          item.content = '<span class="remove">Remove</span>';
        } else {
          item.text = i;
        }
        values.push(item);
        i += 1;
      }
      return values.length ? values : [{ value: 1, text: 1 }];
    },
  },
  methods: {
    eachPrice(qty) {
      const clearanceQty = this.clearance?.qty || 0;
      const price = this.clearance?.price || 0;
      const originalPrice = this.clearance?.originalPrice || 0;
      let eachPrice = 0;
      if (qty <= clearanceQty) {
        const qtyPrice = qty * price;
        if (qtyPrice) {
          eachPrice = qtyPrice / qty;
        }
      } else {
        const clearancePrice = clearanceQty * price;
        const qtyDiffPrice = (qty - clearanceQty) * originalPrice;
        const totalPrice = clearancePrice + qtyDiffPrice;
        if (totalPrice) {
          eachPrice = totalPrice / qty;
        }
      }
      return eachPrice;
    },
  },
};

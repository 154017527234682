
import respUtility from '@/mixins/respUtility';

export default {
  name: 'AboutUsViewFooterDesktop',
  components: {
    Logo: () => import('./Logo.vue'),
  },
  mixins: [respUtility],
  props: {
    footerData: {
      type: Object,
    },
    contactUsItem: {
      type: Object,
    },
    filteredMenus: {
      type: Array,
    },
  },
  data() {
    return {
      isTopButton: false,
    };
  },
  mounted() {
    this.changeTopButtonProps();
    window.addEventListener('resize', this.changeTopButtonProps);
    window.addEventListener('scroll', this.changeTopButtonProps);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.changeTopButtonProps);
    window.removeEventListener('scroll', this.changeTopButtonProps);
  },
  methods: {
    changeTopButtonProps() {
      const { scrollY, innerHeight } = window;
      this.isTopButton = scrollY > innerHeight * 0.25;
    },
  },
};


import respUtility from '~/mixins/respUtility';

export default {
  name: 'HeaderMenuWrapper',
  mixins: [respUtility],
  props: {
    categories: {
      type: Object,
    },
    brands: {
      type: Object,
    },
    isMenuPermanent: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};

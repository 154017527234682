
import widgetSrc from '@/assets/widget-91x73.svg';

export default {
  name: 'FooterNavigationWidget',
  data() {
    return {
      widgetSrc,
    };
  },
  methods: {
    followLink() {
      window.location.href = `${window.location.origin}/reviews`;
    },
  },
};

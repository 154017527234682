// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_Kol2\\+{--_hheader:112px;background:#0e259a;background:var(--primary-main,#0e259a);color:#fff;color:var(--primary-white,#fff)}.header_Kol2\\+.is_boutique_AWNJK{background-color:#000}@media only screen and (min-width:768px){.header_Kol2\\+{--_hheader:72px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header_Kol2+",
	"is_boutique": "is_boutique_AWNJK"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".includes_EG-hQ{align-items:center;display:flex;gap:8px}.includes__title_fTR9M{font-weight:600}.arrow_RBoHZ{color:var(--primary-main);transition:.3s cubic-bezier(.25,.8,.5,1),visibility 0s}.arrow_RBoHZ.is_boutique_DtxI4{--primary-main:#000}.arrow_RBoHZ path{fill:var(--primary-main)}.arrow__active_eSrmf{transform:rotate(180deg)}.list_VfJ7-{display:flex;flex-direction:column;gap:8px;margin-top:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"includes": "includes_EG-hQ",
	"includes__title": "includes__title_fTR9M",
	"arrow": "arrow_RBoHZ",
	"is_boutique": "is_boutique_DtxI4",
	"arrow__active": "arrow__active_eSrmf",
	"list": "list_VfJ7-"
};
module.exports = ___CSS_LOADER_EXPORT___;


import respUtility from '@/mixins/respUtility';

export default {
  /**
   * Component to build Dialog based on v-dialog
   * Events: emit event 'close' onclick on the close button
   * @category Dialogs
   * @component
   * @example <caption>Dialog usage (see code)</caption>
   * <base-dialog v-model="showPopup" @close="() => { this.showPopup = false }"> Hello! </base-dialog>
   * @nuxt-events ['change-global-scrolling']
   */
  name: 'BaseDialog',
  mixins: [respUtility],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
    },
    customClass: {
      type: String,
      default: '',
    },
    permanent: {
      type: Boolean,
      default: false,
    },
    isWriteReview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    contentClassString() {
      return [
        this.$style.dialog,
        this.$attrs.hasExternalScroll ? 'hasExternalScroll' : '',
        this.customClass || '',
        this.$attrs['content-class'] || '',
      ].join(' ');
    },
  },
  watch: {
    value(dialogStatus) {
      this.$nuxt.$emit('change-global-scrolling', !dialogStatus);
    },
  },
  methods: {
    onInnerOverlayClick() {
      if (this.$attrs.persistent) {
        const dialogElem = this.$refs.innerDialog.closest('.v-dialog');
        const className = 'v-dialog--animated';
        const removeClass = () => dialogElem.classList.remove(className);

        dialogElem.addEventListener('animationend', removeClass, { once: true });

        return;
      }

      this.onClose();
    },
    /** Emit close event */
    onClose() {
      this.$emit('close');
    },
    /** Emit the event 'close-message' */
    onCloseMsg(msg) {
      this.$emit('close-message', msg);
    },
  },
};

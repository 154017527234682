
export default {
  name: 'HeaderPromotion',
  data() {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
      interval: null,
    };
  },
  computed: {
    formattedHours() {
      return this.hours < 10 ? `0${this.hours}` : this.hours;
    },
    formattedMinutes() {
      return this.minutes < 10 ? `0${this.minutes}` : this.minutes;
    },
    formattedSeconds() {
      return this.seconds < 10 ? `0${this.seconds}` : this.seconds;
    },
  },
  methods: {
    startCountdown() {
      const now = new Date();
      const endOfDay = new Date();
      endOfDay.setHours(23, 59, 59);

      const totalSeconds = Math.floor((endOfDay - now) / 1000);

      this.updateTime(totalSeconds);

      this.interval = setInterval(() => {
        const remainingTime = this.getRemainingTime();
        if (remainingTime.total <= 0) {
          clearInterval(this.interval);
          return;
        }
        this.updateTime(remainingTime.total - 1);
      }, 1000);
    },
    getRemainingTime() {
      const now = new Date();
      const endOfDay = new Date();
      endOfDay.setHours(23, 59, 59);

      const total = Math.floor((endOfDay - now) / 1000);
      const hours = Math.floor(total / 3600);
      const minutes = Math.floor((total % 3600) / 60);
      const seconds = total % 60;

      return {
        total, hours, minutes, seconds,
      };
    },
    updateTime(totalSeconds) {
      this.hours = Math.floor(totalSeconds / 3600);
      this.minutes = Math.floor((totalSeconds % 3600) / 60);
      this.seconds = totalSeconds % 60;
    },
  },
  mounted() {
    this.startCountdown();
  },
  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v-application.hasOverlay_L5hBo .v-main__wrap>:not(.overlay_xgsKp){pointer-events:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hasOverlay": "hasOverlay_L5hBo",
	"overlay": "overlay_xgsKp"
};
module.exports = ___CSS_LOADER_EXPORT___;


import respUtility from '@/mixins/respUtility';
import { htmlspecialcharsDecode } from '~/mixins/helpers';

export default {
  name: 'CartName',
  mixins: [respUtility],
  props: {
    product: {
      type: Object,
      default() {
        return {};
      },
    },
    /** @prop {Boolean} [mini-cart] - is the item inside the mini-cart or cart page */
    miniCart: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isOnsite() {
      return this.product.webId === 'qbONSITE';
    },
    productName() {
      return htmlspecialcharsDecode(this.product?.name || '');
    },
  },
};

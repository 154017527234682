import { onLoad } from '@/helpers/onLoad';

export default ({ $isSpider, $config }) => {
  const { klaviyo } = $config;
  if ($isSpider || !(klaviyo?.reclaimGeneralEnabledJsTracking || klaviyo?.reclaimGeneralEnabledNewsletter)) {
    return;
  }
  onLoad(() => {
    const scriptEle = document.createElement('script');
    scriptEle.setAttribute(
      'src',
      `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyo.reclaimGeneralPublicApiKey}`,
    );
    scriptEle.setAttribute('async', 'true');
    document.body.appendChild(scriptEle);
  });
};

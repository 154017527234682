// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mobileMenuItem_5OPgV{display:flex;flex-direction:column;padding:2.4rem 0 0;grid-row-gap:.4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileMenuItem": "mobileMenuItem_5OPgV"
};
module.exports = ___CSS_LOADER_EXPORT___;


import respUtility from '@/mixins/respUtility';

export default {
  name: 'AboutUsViewHeaderDesktop',
  mixins: [respUtility],
  props: {
    headerData: {
      type: Object,
    },
  },
};

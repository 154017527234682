import { render, staticRenderFns } from "./Catalog.vue?vue&type=template&id=4f99b422&scoped=true"
import script from "./Catalog.vue?vue&type=script&lang=js"
export * from "./Catalog.vue?vue&type=script&lang=js"
import style0 from "./Catalog.vue?vue&type=style&index=0&id=4f99b422&prod&lang=scss&module=true"
import style1 from "./Catalog.vue?vue&type=style&index=1&id=4f99b422&prod&lang=scss&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "4f99b422",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/var/www/html/js/productlisting/components/common/BaseIcon.vue').default,HeaderMenuBrandsCatalogIndex: require('/var/www/html/js/productlisting/components/templates/header/menu/brands/CatalogIndex.vue').default,HeaderMenuBrandsCatalogLetter: require('/var/www/html/js/productlisting/components/templates/header/menu/brands/CatalogLetter.vue').default})


import authMixin from '@/mixins/authMixin';

/**
 * The auth links block into the mobile header's menu
 * @category [Header, Menus]
 * @component
 * @example <caption>The block usage (see code)</caption>
 * <HeaderMenuMobileAuth />
 * @events ['close']
 */
export default {
  name: 'HeaderMenuMobileAuth',
  mixins: [authMixin],
  computed: {
    customer() {
      return this.$store.state.customer?.me;
    },
  },
  methods: {
    singOut() {
      this.$emit('close');
      this.logOut();
    },
    followLink(slug) {
      window.location.href = `${window.location.origin}/${slug}`;
      this.$nextTick(() => {
        this.$emit('close');
      });
    },
  },
};


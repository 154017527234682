// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".userMenuHeader_7hrqS{font-size:2.1rem;font-weight:600;line-height:3rem;margin-bottom:2.4rem}.infoMenu_8O2ze{border-top:.1rem solid var(--gray-4);margin-top:2.4rem}.buttonWrap_AHFd8,.infoMenu_8O2ze{padding-top:2.4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userMenuHeader": "userMenuHeader_7hrqS",
	"infoMenu": "infoMenu_8O2ze",
	"buttonWrap": "buttonWrap_AHFd8"
};
module.exports = ___CSS_LOADER_EXPORT___;

// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';
/* eslint-disable import/extensions */
import {
  FRAGMENT_MENU_DATA,
  FRAGMENT_BRAND_MENU_DATA,
  FRAGMENT_CART_DATA,
  FRAGMENT_CART_PAGE_DATA,
  FRAGMENT_META_DATA,
  FRAGMENT_CUSTOMER,
} from './apolloCommonFragment.js';
/* eslint-enable import/extensions */

export const GET_CART_DATA = gql`
 ${FRAGMENT_CART_DATA}
  query getCartData($subtotal: Float, $webId: String) {
      cart {
          minicart {
              ...fragmentCartData
          }
          phone {
              phoneByTotal(subtotal: $subtotal, webId: $webId) {
                  phone
                  slug
              }
          }
      }
  }
`;

export const GET_CART_PAGE_DATA = gql`
 ${FRAGMENT_CART_PAGE_DATA}
  query getCartData($subtotal: Float, $webId: String, $zipcode: String) {
    cart {
      cart(zipcode: $zipcode) {
        ...fragmentCartPageData
        discount  
      }
      minicart {
        summaryCount
      }
      phone {
        phoneByTotal(subtotal: $subtotal, webId: $webId) {
          phone
          slug
        }
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  ${FRAGMENT_CUSTOMER}
  query getCustomer {
    customer {
      me {
        ...fragmentCustomer
      }
    }
  }
`;
export const GET_INITIAL_HEADER = gql`
  ${FRAGMENT_CART_DATA}
  ${FRAGMENT_CUSTOMER}
  query getInitialHeader($subtotal: Float, $webId: String) {
    protectionPlan {
      priceTierMatchTable {
        from
        to
        tier
      }
      plans {
        id
        name
        tiers {
          tier
          price
        }
      }
    }
    customer {
      me {
        ...fragmentCustomer
      }
    }
    cart {
      minicart {
        ...fragmentCartData
      }
      phone {
        phoneByTotal(subtotal: $subtotal, webId: $webId) {
          phone
          slug
        }
        phonePriceTier {
          general {
            from
            to
            phone
            slug
          }
          mattress {
            from
            to
            phone
            slug
          }
        }
      }
    }
  }
`;
export const GET_INITIAL_DATA = gql`
  ${FRAGMENT_MENU_DATA}
  ${FRAGMENT_BRAND_MENU_DATA}
  ${FRAGMENT_META_DATA}
  query getInitialData ($slug: String!, $parameters: [Parameters]) {
    pageMetadata(slug: $slug, parameters: $parameters) {
        ...fragmentMetaData
    }
    footerQueries {
      footerMenu {
        menuTitle
        menuLinks {
          name
          slug
        }
      }
      footerGenericBlocks {
        additionalMenu {
          slug
          originalName
          name
        }
      }
    }
    headerQueries {
      navigationCategories {
        ...fragmentMenuData
        subs {
          ...fragmentMenuData
          subs {
            ...fragmentMenuData
          }
        }
      }
      navigationBrands {
        alpha {
          brands {
            ...fragmentBrandMenuData
          }
          letter
        }
        luxury {
          ...fragmentBrandMenuData
        }
      }
      saleTimer
    }
  },
`;
export const GET_INITIAL_CHECKOUT_DATA = gql`
  ${FRAGMENT_META_DATA}
  query getInitialCheckoutData ($slug: String!, $parameters: [Parameters]) {
    pageMetadata(slug: $slug, parameters: $parameters) {
        ...fragmentMetaData
    }
    headerQueries {
      saleTimer
    }
  },
`;
export const GET_META_DATA = gql`
  ${FRAGMENT_META_DATA}
  query getMetaData ($slug: String!) {
    pageMetadata(slug: $slug) {
        ...fragmentMetaData
    }
  },
`;
export const GET_PAGE_TYPE = gql`
  query getPageType ($slug: String!) {
    pageMetadata(slug: $slug) {
      pageType
    }
  },
`;
export const GET_REDIRECT = gql`
  query getRedirect ($slug: String!) {
    pageMetadata(slug: $slug) {
      pageType
      addons {
        ... on RedirectMeta {
          redirect {
            url
            type
          }
        }
      }
    }
  },
`;
export const GET_SEARCH_REDIRECT = gql`
  query getSearchRedirect ($slug: String!, $parameters: [Parameters]) {
    pageMetadata(slug: $slug, parameters: $parameters) {
      addons {
        ... on SearchMeta {
          redirect {
            url
            type
          }
        }
      }
    }
  },
`;


export default {
  name: 'HeaderMenuDesktop',
  props: {
    categories: {
      type: Object,
    },
    brands: {
      type: Object,
    },
    isMenuPermanent: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};

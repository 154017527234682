const dollarFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
const dollarFormatWithoutSymbol = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
const dollarFormatWithoutCents = new Intl.NumberFormat('en-US', {
  style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0,
});

export default {
  methods: {
    classNameFormat(str) {
      return str.toLowerCase()
        .replace(/[^a-z0-9-]/g, '-').replace(/-{2,}/g, '-');
    },
    toKebabCaseFormat(str) {
      return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2')
        .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
        .toLowerCase();
    },
    toCamelCaseFormat(str) {
      return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    },
    toSentenceCaseTextFormat(str) {
      const result = str.replace(/([A-Z])/g, ' $1');
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    formatPrice(value) {
      return dollarFormat.format(value);
    },
    formatPriceWithoutCents(value) {
      return dollarFormatWithoutCents.format(value);
    },
    formatPriceWithoutSymbol(value) {
      return dollarFormatWithoutSymbol.format(value);
    },
  },
};

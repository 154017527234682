
import respUtility from '@/mixins/respUtility';
import FooterDesktop from './Footer/FooterDesktop.vue';
import FooterMobile from './Footer/FooterMobile.vue';

export default {
  name: 'AboutUsViewFooter',
  mixins: [respUtility],
  components: {
    FooterDesktop,
    FooterMobile,
  },
  props: {
    footerData: {
      type: Object,
    },
  },
  computed: {
    contactUsItem() {
      return this?.footerData?.center?.menu?.find((item) => item?.menuTitle === 'Contact us');
    },
    filteredMenus() {
      return this.footerData?.center?.menu.filter((menu) => menu?.menuTitle !== 'Contact us');
    },
  },
};

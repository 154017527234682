
import strUtility from '@/mixins/strUtility';

/**
 * The minicart's item's onsite block
 * @category [Header, Cart, Onsite]
 * @component
 * @example <caption>Block usage (see code)</caption>
 * <HeaderCartItemGuardian :product="product" />
 */
export default {
  name: 'HeaderCartItemGuardian',
  mixins: [strUtility],
  props: {
    /** @prop {Object} [:product="product"] - The product's data */
    product: {
      type: Object,
    },
    /** @prop {Number} [:price="500"] - The product's price */
    price: {
      type: Number,
      default: 0,
    },
  },
};

export default {
  computed: {
    isRespXs() {
      if (process.server) {
        return this.$device.isMobile;
      }
      return this.$vuetify.breakpoint.xsOnly;
    },
    isRespSm() {
      if (process.server) {
        return this.$device.isMobile;
      }
      return this.$vuetify.breakpoint.smOnly;
    },
    isRespSmAndDown() {
      if (process.server) {
        return this.$device.isMobile;
      }
      return this.$vuetify.breakpoint.smAndDown;
    },
    isRespSmAndUp() {
      if (process.server) {
        return this.$device.isMobileOrTablet;
      }
      return this.$vuetify.breakpoint.smAndUp;
    },
    isRespMd() {
      if (process.server) {
        return this.$device.isTablet;
      }
      return this.$vuetify.breakpoint.mdOnly;
    },
    isRespMdAndUp() {
      if (process.server) {
        return this.$device.isDesktopOrTablet;
      }
      return this.$vuetify.breakpoint.mdAndUp;
    },
    isRespMdAndDown() {
      if (process.server) {
        return this.$device.isMobileOrTablet;
      }
      return this.$vuetify.breakpoint.mdAndDown;
    },
    isRespLgAndUp() {
      if (process.server) {
        return this.$device.isDesktop;
      }
      return this.$vuetify.breakpoint.lgAndUp;
    },
    isRespLgAndDown() {
      if (process.server) {
        return this.$device.isDesktopOrTablet;
      }
      return this.$vuetify.breakpoint.lgAndDown;
    },
    respClass() {
      return this.$device.isMobileOrTablet ? 'mobile-view' : 'desktop-view';
    },
    isTouchDevice() {
      const device = this.$store.state.deviceType || this.$customDevice;
      if (device && !this.$isSpider) {
        // eslint-disable-next-line default-case
        switch (device) {
          case 'mobile': return true;
          case 'tablet': return true;
          case 'desktop': return false;
        }
      }
      return this.$device.isMobileOrTablet;
    },
  },
};

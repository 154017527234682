
import respUtility from '@/mixins/respUtility';
import HeaderDesktop from './Header/HeaderDesktop.vue';
import HeaderMobile from './Header/HeaderMobile.vue';

export default {
  name: 'AboutUsViewHeader',
  mixins: [respUtility],
  components: {
    HeaderDesktop,
    HeaderMobile,
  },
  props: {
    headerData: {
      type: Object,
    },
    sidebarData: {
      type: Array,
    },
  },
  methods: {
    toggleMobileMenu(newValue) {
      this.isMobileMenuOpen = newValue;
    },
  },
};


/**
 * The button that scrolls to the top of the page.
 * @category Buttons
 * @component
 * @example <caption>FooterScrollTopButton usage (see code)</caption>
 * <FooterScrollTop />
 */
export default {
  name: 'FooterScrollTop',
  computed: {
    iconColor() {
      return this.isBoutiquePage ? '#8a6301' : '#F25D0D';
    },
    isBoutiquePage() {
      return this.$store.state.metaData?.addons?.head?.isBoutique || false;
    },
  },
  methods: {
    scrollIntoViewAndWait(element) {
      return new Promise((resolve) => {
        if ('onscrollend' in window) {
          document.addEventListener('scrollend', resolve, { once: true });
          element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        } else {
          element.scrollIntoView({ block: 'start', inline: 'start' });
          resolve();
        }
      });
    },
    async handleScroll() {
      const el = document.querySelector('#__nuxt');
      if (!el) {
        return;
      }
      await this.scrollIntoViewAndWait(el);
    },
    /** Scroll to the top */
    scrollTop() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
      }, 20);
    },
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form_vIr6F{background:var(--gray-9);border-radius:8px;display:flow-root;padding:16px 16px 32px}@media only screen and (min-width:768px){.form_vIr6F{padding:0 24px 20px}}.form_vIr6F .child_x02Us:first-child{border-top:initial;gap:40px}@media only screen and (min-width:768px){.form_vIr6F .child_x02Us:first-child{gap:24px;margin-top:32px}}.form_vIr6F.isOneItemView_JgMTA{padding-bottom:16px;padding-top:48px}@media only screen and (min-width:768px){.form_vIr6F.isOneItemView_JgMTA{padding-bottom:24px;padding-top:44px}:root .pplanVariants_j7Hxo.isOneItemView_JgMTA{gap:16px}}.child_x02Us{align-items:stretch;border-top:1px solid var(--gray-3);display:flex;flex-direction:column;flex-wrap:wrap;gap:8px;padding:16px 0 20px}@media only screen and (min-width:768px){.child_x02Us{align-items:flex-start;flex-direction:row;gap:24px;padding:16px 0 12px}}.child__description_2aZn3{align-items:flex-start;color:var(--primary-black);display:flex;flex-grow:1;font-size:1.4rem;gap:12px;line-height:2rem;max-width:328px;width:100%}@media only screen and (min-width:768px){.child__description_2aZn3{font-size:1.6rem;gap:16px;line-height:2.2rem;padding:2px 0}}.child__image_JALey{background-color:var(--primary-white);flex-shrink:0;height:56px;-o-object-fit:contain;object-fit:contain;width:56px}@media only screen and (min-width:768px){.child__image_JALey{height:64px;margin-top:-2px;width:64px}}.subtotal_U7ojz{align-items:baseline;border-top:1px solid var(--gray-3);color:var(--primary-black);display:flex;font-size:1.4rem;font-weight:600;gap:16px;justify-content:flex-end;line-height:2rem;margin-top:4px;padding-top:16px;text-align:right}@media only screen and (min-width:768px){.subtotal_U7ojz{margin-top:4px;padding-top:16px}}.subtotal__summ_ckGa2{font-size:1.4rem;line-height:2rem;margin-left:auto;width:107px}@media only screen and (min-width:768px){.subtotal__summ_ckGa2{font-size:1.6rem;line-height:2.2rem;margin-left:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form_vIr6F",
	"child": "child_x02Us",
	"isOneItemView": "isOneItemView_JgMTA",
	"pplanVariants": "pplanVariants_j7Hxo",
	"child__description": "child__description_2aZn3",
	"child__image": "child__image_JALey",
	"subtotal": "subtotal_U7ojz",
	"subtotal__summ": "subtotal__summ_ckGa2"
};
module.exports = ___CSS_LOADER_EXPORT___;


import strUtility from '@/mixins/strUtility';
import { htmlspecialcharsDecode } from '~/mixins/helpers';
/**
 * The product protection plan variants
 * @category [Protection Plan]
 * @component
 * @example <caption>Dialog usage (see code)</caption>
 * <ProtectionPlanPopupPlans :items="items" @set-variant="setProtection" />
 * @events ['set-variant']
 */
export default {
  name: 'ProtectionPlanPopupPlans',
  mixins: [strUtility],
  props: {
    /** @prop {Array} [:items="items"] - The protection items */
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    /** @prop {Object} [:chosen="chosen"] - The chosen plans */
    chosen: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    summ() {
      return this.items.reduce((acc, item) => {
        const plan = item.plans.find((p) => this.chosen[item.itemId] === p.id);
        return acc + (plan?.price || 0) * item.qty;
      }, 0);
    },
  },
  methods: {
    setProtection(itemId, v) {
      const result = {
        ...this.chosen,
        [itemId]: v,
      };
      this.$emit('set-variant', result);
    },
    decodeName(name) {
      return htmlspecialcharsDecode(name || '');
    },
  },
};

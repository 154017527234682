// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".price_jTrHB{display:flex;flex-direction:column;margin:.4rem 0 0;text-align:right}.old_zt8fp{color:var(--gray-1);line-height:2rem;-webkit-text-decoration:line-through;text-decoration:line-through}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price": "price_jTrHB",
	"old": "old_zt8fp"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".links_uz01d{display:grid;grid-row-gap:2.4rem}.link_yHZbH{align-items:center;display:flex;grid-gap:1.6rem;color:var(--primary-black)!important}.link_yHZbH:hover{-webkit-text-decoration:underline;text-decoration:underline}.icon_iaize{color:var(--primary-main);flex:0 0 auto;height:2.4rem;width:2.4rem}.title_bVqnN{font-size:1.8rem;line-height:2.6rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"links": "links_uz01d",
	"link": "link_yHZbH",
	"icon": "icon_iaize",
	"title": "title_bVqnN"
};
module.exports = ___CSS_LOADER_EXPORT___;

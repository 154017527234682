const state = () => ({
  popularCategory: [],
});

export default {
  state,
  mutations: {
    setPopularCategory(state, value) {
      state.popularCategory = value;
    },
  },
};

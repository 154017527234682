
import strUtility from '@/mixins/strUtility';
/**
 * The minicart's item's price
 * @category [Header, Cart]
 * @component
 * @example <caption>Block usage (see code)</caption>
 * <HeaderCartItemPrice :price="price" :old-price="product.price.msrp" />
 */
export default {
  name: 'HeaderCartItemPrice',
  mixins: [strUtility],
  props: {
    /** @prop {Number} [:price="500"] - The product's price */
    price: {
      type: Number,
      default: 0,
    },
    /** @prop {Number} [:old-price="500"] - The product's old price */
    oldPrice: {
      type: Number,
      default: 0,
    },
    /** @prop {Boolean} [:show-msrp="true"] - Show product's msrp */
    showMsrp: {
      type: Boolean,
      default: true,
    },
  },
};

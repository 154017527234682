
export default {
  name: 'HeaderMenuMobileLinks',
  data: () => ({
    links: [
      {
        iconName: 'IconHeaderAccount-24x24',
        title: 'My Account',
        href: '/customer/account/login',
      },
      {
        iconName: 'IconHeaderRecently-24x24',
        title: 'Recently Viewed',
        href: '/recently-viewed',
      },
      {
        iconName: 'IconHeaderHelpCenter-24x24',
        title: 'Help Center',
        href: '/help-center',
      },
    ],
  }),
  computed: {
    linksUpdate() {
      const links = [
        ...this.links,
        {
          iconName: 'IconHeaderContact-24x24',
          title: 'Contact Us',
          href: `${this.$config.supportUrl}/hc/en-us/requests/new`,
        },
      ];
      return links;
    },
  },
};


import { mapMutations } from 'vuex';
import strUtility from '@/mixins/strUtility';
/**
 * The full minicart's content
 * @category [Header, Cart]
 * @component
 * @example <caption>Block usage (see code)</caption>
 * <HeaderCartContent :cart="cart" :new-products="newItems" @open-popup="openPopup" />
 * @events ['open-popup']
 */
export default {
  name: 'HeaderCartContent',
  mixins: [strUtility],
  props: {
    /** @prop {Object} [:cart="cart"] - gql CartType */
    cart: {
      type: Object,
      required: true,
    },
    /** @prop {Array} [:new-products="newItems"] - [String] - product ids */
    newProducts: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      timeoutId: null,
      newProductsStatusTimeout: 4000,
    };
  },
  watch: {
    cart() {
      this.hideNewStatus();
    },
  },
  /** Turn on the timeout to display the popup */
  mounted() {
    this.hideNewStatus();
  },
  beforeDestroy() {
    if (this.newProducts.length) {
      this.clearNewProducts();
    }
    clearTimeout(this.timeoutId);
  },
  methods: {
    ...mapMutations({
      /** without arguments, it set an empty array as new items */
      clearNewProducts: 'cart/setNewItems',
    }),
    hideNewStatus() {
      if (this.newProducts.length) {
        this.timeoutId = setTimeout(this.clearNewProducts, this.newProductsStatusTimeout);
      }
    },
    /** Toggle loading status */
    toggleLoading() {
      this.loading = !this.loading;
      if (!this.loading) {
        this.$emit('reload');
      }
    },
  },
};

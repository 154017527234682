
/**
 * The list of social networks.
 * @category footer
 * @component
 * @example <caption>FooterSocials usage (see code)</caption>
 * <FooterSocials />
 */
export default {
  name: 'FooterSocials',
  data() {
    return {
      /** @data {Array} list of social networks */
      socials: [
        {
          href: 'https://www.facebook.com/1StopBedrooms/',
          iconName: 'IconSocialsFacebook-32x32',
        },
        {
          href: 'https://twitter.com/1stopbedrooms',
          iconName: 'IconSocialsX-32x32',
        },
        {
          href: 'https://www.instagram.com/1stopbedrooms/',
          iconName: 'IconSocialsInstagram-32x32',
        },
        {
          href: 'https://www.youtube.com/channel/UCpM_Vp2lXmLf6Vm8O1_KYvA',
          iconName: 'IconSocialsYoutube-32x32',
        },
      ],
    };
  },
};


import respUtility from '@/mixins/respUtility';

/**
 * The content of the header's categories submenu
 * @category [Header, Menus]
 * @component
 * @example <caption>The block usage (see code)</caption>
 * <HeaderMenuCategoriesItemLink :menu-item="item" is-parent-level />
 */
export default {
  name: 'HeaderMenuCategoriesItemLink',
  mixins: [respUtility],
  props: {
    /** @prop {Object} [:menu-item="item"] - The categories menu's item - gql TreeMenuItemType! */
    menuItem: {
      type: Object,
      required: true,
    },
    /** @prop {Boolean} [is-parent-level] - The categories menu's item's index - for id */
    isParentLevel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /** @return {Boolean} */
    hasSubmenu() {
      if (!this.isParentLevel) {
        return false;
      }
      return (this.menuItem.subs && this.menuItem.subs.length) || this.menuItem.slug === 'clearance-deals';
    },
  },
};

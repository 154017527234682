// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lottie_ua00V{display:block;opacity:1;overflow:hidden;text-align:center;transform:translateZ(0)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lottie": "lottie_ua00V"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form_gzPeH{display:flex;flex-grow:1;flex-wrap:wrap;gap:8px}@media only screen and (min-width:768px){.form_gzPeH{flex-wrap:nowrap;gap:16px}}@media only screen and (min-width:1264px){.form_gzPeH{flex-grow:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form_gzPeH"
};
module.exports = ___CSS_LOADER_EXPORT___;

/**
 * Author: Alex Sheplyakov <alexdev@wiserbrand.com>
 * 1StopBedrooms.com, All rights reserved.
 */

class TaskManager {
  constructor() {
    this.taskQueue = [];
    this.queueActive = false;
    this.delayFunctionInternal = false;
  }

  delayFunction(callback) {
    if (!this.delayFunctionInternal) {
      if ('requestIdleCallback' in window) {
        this.delayFunctionInternal = window.requestIdleCallback;
      } else {
        this.delayFunctionInternal = window.requestAnimationFrame;
      }
    }
    this.delayFunctionInternal.call(window, callback);
  }

  append(callback) {
    this.taskQueue.push(callback);
    if (!this.queueActive) {
      this.queueActive = true;
      this.delayFunction(() => {
        this.runner();
      });
    }
  }

  runner() {
    const callback = this.taskQueue.pop();
    callback();

    if (this.taskQueue.length) {
      this.delayFunction(() => {
        this.runner();
      });
    } else {
      this.queueActive = false;
    }
  }
}

export default (_unused, inject) => {
  const taskManager = new TaskManager();
  inject('taskManager', taskManager);
};

// vim: ft=javascript:sw=2:ts=2:fdm=marker:

const state = () => ({
  aboutMetaData: {
  },
  aboutheaderData: {
  },
  aboutFooterData: {
  },
  welcomeSubmitted: false,
});

export default {
  state,
  mutations: {
    setAboutMetaData(state, data) {
      state.aboutMetaData = { ...state.aboutMetaData, ...data };
    },
    setAboutheaderData(state, data) {
      state.aboutheaderData = { ...state.aboutheaderData, ...data };
    },
    setAboutFooterData(state, data) {
      state.aboutFooterData = { ...state.aboutFooterData, ...data };
    },
    setWelcomeSubmitted(state, welcomeSubmitted) {
      state.welcomeSubmitted = welcomeSubmitted;
    },
  },
  actions: {
  },
};

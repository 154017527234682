// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".image_F-NaD{display:flex;min-height:var(--image-min-height);min-width:var(--image-min-width);overflow:hidden;position:relative}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "image_F-NaD"
};
module.exports = ___CSS_LOADER_EXPORT___;


export default {
  name: 'FooterNavigationMenu',
  props: {
    menu: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    isInternalLink(slug) {
      const internalSlugs = [
        '/about-us', '/careers', '/our-story', '/1stop-advantage',
        '/1stop-cares', '/partner-with-us', '/welcome-1stopbedrooms',
        '/open-positions', '/job-description', '/reviews',
      ];
      return internalSlugs.includes(slug);
    },
    handleClick(link, event) {
      if (process.client) {
        event.preventDefault();
        event.stopPropagation();

        const currentSlug = this.$route.path[0] === '/' ? this.$route.path.slice(1) : this.$route.path;
        if (link.slug.includes(currentSlug)) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      }
    },
  },
};

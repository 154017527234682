
export default {
  name: 'nuxt-error',
  layout: 'default', // optional
  props: {
    error: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    errorPage() {
      if (this.error.statusCode === 404) {
        return 'Error404';
      }
      // catch everything else
      return 'Error404';
    },
  },
};

import { onLoad } from '@/helpers/onLoad';

export default ({
  $isSpider, $config, $customDevice, store,
}) => {
  if ($isSpider || $customDevice === 'mobile' || $customDevice === 'tablet') {
    return;
  }

  const variants = {
    [`(min-width: ${$config.breakpoints.tablet}px)`]: 'tablet',
    [`(min-width: ${$config.breakpoints.desktop}px)`]: 'desktop',
  };
  const breakpoints = Object.keys(variants);
  const changeMedia = () => {
    let device = 'mobile';
    breakpoints.forEach((p) => {
      if (window.matchMedia(p)?.matches) {
        device = variants[p];
      }
    });
    store.commit('setDeviceType', device);
  };
  onLoad(() => {
    breakpoints.forEach((p) => {
      window.matchMedia(p)?.addEventListener('change', changeMedia);
    });
  });
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".image_jcQJc{flex:0 0 auto;margin-right:1.6rem}.image_jcQJc[alt=Clearance]{align-items:center;height:72px;justify-content:center;width:95px}@media only screen and (min-width:1264px){.image_jcQJc.imageParent_9QHDf{margin-right:0}}.disabled_glTRW{pointer-events:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "image_jcQJc",
	"imageParent": "imageParent_9QHDf",
	"disabled": "disabled_glTRW"
};
module.exports = ___CSS_LOADER_EXPORT___;

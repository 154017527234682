// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mobileSubMenuWrapper_z5mKM{--_inversion-t-pffp:calc(1 - var(--t-pffp, 1));--_header-height:var(--hheader,var(--_hheader));height:calc(100% - var(--_header-height)*0);height:calc(100% - var(--_inversion-t-pffp)*var(--_header-height));padding:0 env(safe-area-inset-right) 0 env(safe-area-inset-left);top:calc(var(--_header-height)*0);top:calc(var(--_inversion-t-pffp)*var(--_header-height))}.mobileSubMenuContent_fjdnr{background:var(--primary-white);display:flex;flex-direction:column;min-height:100%;overflow-y:auto}.mobileMenuContentBrands_5kDbS{background:var(--gray-9)}.backBtn_tAD8\\+{background:var(--gray-8);padding:1.6rem}.backBtnContent_lV6vz{align-items:center;color:var(--primary-main);display:flex;grid-gap:.8rem;font-size:1.6rem;font-weight:600;line-height:2.2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileSubMenuWrapper": "mobileSubMenuWrapper_z5mKM",
	"mobileSubMenuContent": "mobileSubMenuContent_fjdnr",
	"mobileMenuContentBrands": "mobileMenuContentBrands_5kDbS",
	"backBtn": "backBtn_tAD8+",
	"backBtnContent": "backBtnContent_lV6vz"
};
module.exports = ___CSS_LOADER_EXPORT___;
